<template>
    <div class="col-sm-12">
        <!-- Page title -->
        <h2>User list</h2>
        <br />

        <!-- Add button -->
        <router-link
            tag="button"
            to="create"
            type="button"
            class="btn btn-outline-success"
        >
            Add user
        </router-link>

        <br />
        <br />

        <!-- User list -->
        <app-table
            :category="'user'"
            :items="users"
            :cols="[
                { title: 'Name', key: 'name' },
                { title: 'Email', key: 'email' },
                { title: 'Role', key: 'role', type: 'role' },
                { title: 'Added', key: 'added', type: 'date' },
            ]"
        ></app-table>
    </div>
</template>

<script>
import { apiClient } from './../../services/apiClient.js';
import Table from './../Utils/Table.vue';

export default {
    data: function () {
        return {
            users: [],
        };
    },

    components: { 'app-table': Table },

    computed: {
        user() {
            return this.$store.state.auth.user;
        },
    },

    methods: {
        async fetchData() {
            this.users = await apiClient.getList('user');
        },
    },

    async created() {
        await this.fetchData();
    },
};
</script>

<style scoped></style>
