<template>
    <div class="col-sm-12" v-if="Object.keys(displayedUser).length !== 0">
        <h2>{{ displayedUser.name }}</h2>
        <p class="__secondary">ID: {{ displayedUser._id }}</p>

        <br />

        <ul class="nav nav-tabs nav-justified md-tabs indigo" role="tablist">
            <!-- Details tab intro -->
            <li class="nav-item">
                <a
                    class="nav-link active"
                    data-toggle="tab"
                    href="#__details"
                    role="tab"
                    aria-controls="__details"
                    aria-selected="true"
                    ><strong>Details</strong></a
                >
            </li>

            <!-- Preferences tab intro -->
            <li class="nav-item">
                <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#__preferences"
                    role="tab"
                    aria-controls="__preferences"
                    aria-selected="false"
                    ><strong>Preferences</strong></a
                >
            </li>
        </ul>

        <div class="tab-content card pt-5">
            <!-- Details tab -->
            <div
                class="tab-pane fade show active"
                id="__details"
                role="tabpanel"
            >
                <table class="table">
                    <tbody>
                        <tr>
                            <td><strong>User name</strong></td>
                            <td class="text-light">
                                <input
                                    type="text"
                                    class="form-control form-control-sm bg-dark text-light"
                                    v-model="displayedUser.name"
                                />
                            </td>
                        </tr>

                        <tr>
                            <td><strong>Email address</strong></td>
                            <td class="text-light">
                                <input
                                    type="text"
                                    class="form-control form-control-sm bg-dark text-light"
                                    v-model="displayedUser.email"
                                />
                            </td>
                        </tr>

                        <tr>
                            <td><strong>Role</strong></td>
                            <td class="text-light">
                                <select
                                    id="location"
                                    class="form-control-sm bg-dark text-light"
                                    v-model="displayedUser.role"
                                >
                                    <option
                                        v-for="(role, key) in settings.userRole"
                                        :key="key"
                                        :value="key"
                                    >
                                        {{ role }}
                                    </option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td><strong>Active</strong></td>
                            <td class="text-light">
                                <div class="custom-control custom-switch">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="verifiedUser"
                                        v-model="displayedUser.verified"
                                    />
                                    <label
                                        class="custom-control-label"
                                        for="verifiedUser"
                                    ></label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td><strong>Added on</strong></td>
                            <td class="text-light">
                                <app-date
                                    :value="displayedUser.added"
                                ></app-date>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Preferences tab -->
            <div class="tab-pane fade" id="__preferences" role="tabpanel">
                <table class="table">
                    <tbody>
                        <tr>
                            <td><strong>Receive nightly reports</strong></td>
                            <td class="text-light">
                                <div class="custom-control custom-switch">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="reportMail"
                                        v-model="
                                            displayedUser.preferences.mailing
                                                .report
                                        "
                                    />
                                    <label
                                        class="custom-control-label"
                                        for="reportMail"
                                    ></label>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <strong>Receive maintenance tickets</strong>
                            </td>
                            <td class="text-light">
                                <div class="custom-control custom-switch">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="maintenanceTickets"
                                        v-model="
                                            displayedUser.preferences.mailing
                                                .ticket
                                        "
                                    />
                                    <label
                                        class="custom-control-label"
                                        for="maintenanceTickets"
                                    ></label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <br />
        <br />

        <!-- Buttons -->
        <div v-if="user.role > 2">
            <button
                type="button"
                class="btn btn-outline-success"
                @click="updateUser()"
            >
                Save changes
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
                type="button"
                class="btn btn-outline-danger"
                @click="deleteUser()"
            >
                Delete user
            </button>
        </div>
    </div>
</template>

<script>
import { apiClient } from './../../services/apiClient.js';
import DateDisplay from './../Utils/Date.vue';

export default {
    data: function () {
        return {
            displayedUser: {},
        };
    },

    computed: {
        user() {
            return this.$store.state.auth.user;
        },

        settings() {
            return this.$store.state.settings.settings;
        },
    },

    components: { 'app-date': DateDisplay },

    methods: {
        async updateUser() {
            await apiClient.itemUpdate(this.displayedUser, 'user');
            await this.fetchData();
        },

        async deleteUser() {
            if (await apiClient.itemDelete(this.displayedUser, 'user')) {
                this.$router.push('list');
            }
        },

        async fetchData() {
            this.displayedUser = await apiClient.getItem(
                this.$route.params.id,
                'user'
            );
        },
    },

    async created() {
        await this.fetchData();
    },
};
</script>

<style scoped>
@import './../../assets/css/single.css';
</style>
