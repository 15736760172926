<template>
    <div class="col-sm-12" v-if="settings">
        <h2>Report details</h2>

        <form class="form-inline">
            <label for="date" style="margin-right: 1em">Date:</label>
            <router-link
                tag="i"
                class="fas fa-chevron-left"
                style="margin-left: 20px"
                :to="'/report/view/' + previousDate"
            >
            </router-link>
            <input
                type="date"
                class="input-lg form-control form-control-sm bg-dark text-light text-center"
                :value="date"
                style="display: inline"
                @input="$router.push('/report/view/' + $event.target.value)"
            />
            <router-link
                tag="i"
                class="fas fa-chevron-right"
                style="margin-left: 20px"
                :to="'/report/view/' + nextDate"
            >
            </router-link>
        </form>

        <br />

        <template v-if="item">
            <ul
                class="nav nav-tabs nav-justified md-tabs indigo"
                role="tablist"
            >
                <!-- General tab intro -->
                <li class="nav-item">
                    <a
                        class="nav-link active"
                        data-toggle="tab"
                        href="#__general"
                        role="tab"
                        aria-controls="__general"
                        aria-selected="true"
                        ><strong>General</strong></a
                    >
                </li>

                <!-- Tours tab intro -->
                <li class="nav-item">
                    <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#__tours"
                        role="tab"
                        aria-controls="__tours"
                        aria-selected="false"
                        ><strong>Tours</strong></a
                    >
                </li>

                <!-- Stats tab intro -->
                <!-- <li class="nav-item">
                    <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#__stats"
                        role="tab"
                        aria-controls="__stats"
                        aria-selected="false"
                        ><strong>Stats</strong></a
                    >
                </li> -->
            </ul>

            <div class="tab-content card pt-5">
                <!-- General tab -->
                <div
                    class="tab-pane fade show active"
                    id="__general"
                    role="tabpanel"
                >
                    <h4>Tours</h4>
                    <table
                        class="table"
                        v-if="item.tours && item.tours.length > 0"
                    >
                        <thead>
                            <tr class="__tableHead">
                                <th>Tour</th>
                                <th>Capacity</th>
                                <th>Booked</th>
                                <th>Joined</th>
                                <th>Transfers</th>
                                <th>Open dated</th>
                                <th>Refunds</th>
                                <th>No-show</th>
                                <th>Status</th>
                                <th>Options</th>
                                <th>Guide</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(tour, index) in item.tours"
                                :key="index"
                                :class="{ __greyed: tour.status === 1 }"
                            >
                                <td>
                                    <strong
                                        >{{ tour.name }} -
                                        {{ settings.language[tour.language] }}
                                    </strong>
                                </td>
                                <td>
                                    {{ tour.capacity }}
                                </td>
                                <td
                                    :class="{ invisible: tour.paxBooked === 0 }"
                                >
                                    {{ tour.paxBooked }}
                                </td>
                                <td
                                    :class="{ invisible: tour.paxJoined === 0 }"
                                >
                                    {{ tour.paxJoined }}
                                </td>
                                <td
                                    :class="{ invisible: tour.transfers === 0 }"
                                >
                                    {{ tour.transfers }}
                                </td>
                                <td
                                    :class="{ invisible: tour.openDated === 0 }"
                                >
                                    {{ tour.openDated }}
                                </td>
                                <td :class="{ invisible: tour.refunds === 0 }">
                                    {{ tour.refunds }}
                                </td>
                                <td :class="{ invisible: tour.noShow === 0 }">
                                    {{ tour.noShow }}
                                </td>
                                <td>
                                    {{ settings.tourStatus[tour.status] }}
                                </td>
                                <td>
                                    <p v-if="tour.options">X</p>
                                </td>
                                <td>
                                    {{ tour.guides }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <br />
                    <br />

                    <h4>Notes</h4>
                    <p v-if="item.officeNote" class="text-light __text-bloc">
                        {{ item.officeNote }}
                    </p>
                    <p v-else class="text-light">No note from reception</p>

                    <br />
                    <br />

                    <h4>Weather forecast</h4>
                    <table class="table" v-if="item.team">
                        <tbody>
                            <tr>
                                <td><strong>Early night</strong></td>
                                <td class="text-light">
                                    {{ item.forecast.evalEarly }} %
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Late night</strong></td>
                                <td class="text-light">
                                    {{ item.forecast.evalLate }} %
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Note</strong></td>
                                <td class="text-light __text-bloc">
                                    {{ item.forecast.note }}
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Night status</strong></td>
                                <td class="text-light">
                                    {{ settings.nightStatus[item.nightStatus] }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <br />
                    <br />

                    <h4>Team</h4>
                    <table class="table" v-if="item.team">
                        <tbody>
                            <tr v-if="item.team.opsLeader != ''">
                                <td><strong>Opsleader</strong></td>
                                <td class="text-light">
                                    {{ item.team.opsLeader }}
                                </td>
                            </tr>
                            <tr v-if="item.team.customerService != ''">
                                <td><strong>Receptionists</strong></td>
                                <td class="text-light">
                                    {{ item.team.customerService }}
                                </td>
                            </tr>
                            <tr v-if="item.team.mjDrivers != ''">
                                <td><strong>Mount John drivers</strong></td>
                                <td class="text-light">
                                    {{ item.team.mjDrivers }}
                                </td>
                            </tr>
                            <tr v-if="item.team.cwDrivers != ''">
                                <td><strong>Cowan's drivers</strong></td>
                                <td class="text-light">
                                    {{ item.team.cwDrivers }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Tours tab -->
                <div class="tab-pane fade" id="__tours" role="tabpanel">
                    <div
                        v-for="(tour, index) in item.tours"
                        :key="index"
                        :class="{ __greyed: tour.status === 1 }"
                    >
                        <h4>
                            {{ tour.name }} -
                            {{ settings.tourStatus[tour.status] }}
                        </h4>
                        <p class="__secondary">
                            Capacity: {{ tour.capacity }} | Booked:
                            {{ tour.paxBooked }} | Joined:
                            {{ tour.paxJoined }} | No-show: {{ tour.noShow }}
                            <span v-if="tour.options"> | Options offered</span>
                        </p>
                        <table class="table">
                            <tbody>
                                <tr v-if="tour.guides">
                                    <td style="width: 20%">
                                        <strong>Guide</strong>
                                    </td>
                                    <td>{{ tour.guides }}</td>
                                </tr>
                                <tr
                                    v-if="
                                        typeof tour.weather.eval !== 'undefined'
                                    "
                                >
                                    <td style="width: 20%">
                                        <strong>Weather quality</strong>
                                    </td>
                                    <td>{{ tour.weather.eval }} %</td>
                                </tr>
                                <tr v-if="tour.weather.note !== ''">
                                    <td style="width: 20%">
                                        <strong>Weather details</strong>
                                    </td>
                                    <td>{{ tour.weather.note }}</td>
                                </tr>
                                <tr v-if="tour.note">
                                    <td style="width: 20%">
                                        <strong>Tour notes</strong>
                                    </td>
                                    <td class="__text-bloc">{{ tour.note }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <hr class="__sep" />
                    </div>
                </div>

                <!-- Stats tab -->
                <!-- <div class="tab-pane fade" id="__stats" role="tabpanel">
                    <table class="table" v-if="item.stats">
                        <tbody>
                            <tr>
                                <td>
                                    <strong>Average weather quality</strong>
                                </td>
                                <td>{{ item.stats.cloudCover }}%</td>
                            </tr>
                            <tr>
                                <td><strong>Total capacity</strong></td>
                                <td>{{ item.stats.totalCapacity }}</td>
                            </tr>
                            <tr>
                                <td><strong>Total booked</strong></td>
                                <td>
                                    {{ item.stats.totalBooked }} ({{
                                        item.stats.percentOccupancy
                                    }}% of capacity)
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Total joined</strong></td>
                                <td>
                                    {{ item.stats.totalJoined }} ({{
                                        item.stats.percentJoined
                                    }}% of booked)
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Total no-show</strong></td>
                                <td>{{ item.stats.totalNoShow }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div> -->
            </div>

            <br />
            <br />

            <!-- Buttons -->
            <div v-if="user.role > 2">
                <router-link
                    tag="button"
                    :to="'/report/' + date"
                    type="button"
                    class="btn btn-outline-info"
                >
                    Edit
                </router-link>
                &nbsp;&nbsp;&nbsp;
                <button
                    type="button"
                    class="btn btn-outline-info"
                    @click="processReport()"
                >
                    Re-process report
                </button>
            </div>
        </template>
        <template v-else> No report found for this date. </template>
    </div>
</template>

<script>
import { eventBus } from './../../services/eventBus.js';
import { apiClient } from './../../services/apiClient.js';

export default {
    data: function () {
        return {
            item: {},
        };
    },

    computed: {
        user() {
            return this.$store.state.auth.user;
        },

        settings() {
            return this.$store.state.settings.settings;
        },

        date() {
            if (this.$route.params.date) return this.$route.params.date;
            else {
                let now = new Date();
                now.setDate(now.getDate() - 1);
                let dateDay =
                    now.getDate() < 10 ? '0' + now.getDate() : now.getDate();
                let dateMonth =
                    now.getMonth() + 1 < 10
                        ? '0' + (now.getMonth() + 1)
                        : now.getMonth() + 1;
                return now.getFullYear() + '-' + dateMonth + '-' + dateDay;
            }
        },

        previousDate() {
            let baseDate = new Date(this.date);
            baseDate.setDate(baseDate.getDate() - 1);
            let dateDay =
                baseDate.getDate() < 10
                    ? '0' + baseDate.getDate()
                    : baseDate.getDate();
            let dateMonth =
                baseDate.getMonth() + 1 < 10
                    ? '0' + (baseDate.getMonth() + 1)
                    : baseDate.getMonth() + 1;
            return baseDate.getFullYear() + '-' + dateMonth + '-' + dateDay;
        },

        nextDate() {
            let baseDate = new Date(this.date);
            baseDate.setDate(baseDate.getDate() + 1);
            let dateDay =
                baseDate.getDate() < 10
                    ? '0' + baseDate.getDate()
                    : baseDate.getDate();
            let dateMonth =
                baseDate.getMonth() + 1 < 10
                    ? '0' + (baseDate.getMonth() + 1)
                    : baseDate.getMonth() + 1;
            return baseDate.getFullYear() + '-' + dateMonth + '-' + dateDay;
        },
    },

    watch: {
        async date() {
            await this.fetchData();
        },
    },

    methods: {
        async processReport() {
            await apiClient.reProcessReport(this.date);
            await this.fetchData();
        },

        async fetchData() {
            this.item = await apiClient.getReportByDate(this.date);
        },

        async previousDay() {
            // this.date = this.date.getDate() + 1;
        },

        async nextDay() {},
    },

    async created() {
        await this.fetchData();

        eventBus.$on('update-item', async (newItem) => {
            this.item = await newItem;
        });
    },
};
</script>

<style scoped>
@import './../../assets/css/single.css';
</style>
