<template>
    <div class="col-sm-12">
        <!-- Page title -->
        <h2>Equipment list ({{ cat }})</h2>
        <br />

        <!-- Add button -->
        <router-link
            tag="button"
            to="create"
            type="button"
            class="btn btn-outline-success"
        >
            Add {{ cat }}
        </router-link>

        <br />
        <br />

        <!-- Facility list -->
        <app-table
            v-if="cat === 'facility'"
            :category="'equipment'"
            :items="items"
            :cols="[
                { title: 'Name', key: 'name' },
                { title: 'Description', key: 'description' },
                { title: 'Location', key: 'location', type: 'location' },
            ]"
        ></app-table>

        <!-- Vehicle list -->
        <app-table
            v-if="cat === 'vehicle'"
            :category="'equipment'"
            :items="items"
            :cols="[
                { title: 'Type', key: 'type', type: 'type' },
                { title: 'Name', key: 'name' },
                { title: 'License plate', key: 'description' },
                { title: 'WoF', key: 'wof', type: 'date' },
                {
                    title: 'Registration',
                    key: 'registration',
                    type: 'date',
                },
            ]"
        ></app-table>

        <!-- Gear list -->
        <app-table
            v-if="cat === 'gear'"
            :category="'equipment'"
            :items="items"
            :cols="[
                { title: 'Type', key: 'type', type: 'type' },
                { title: 'Name', key: 'name' },
                { title: 'Description', key: 'description' },
                { title: 'Location', key: 'location', type: 'location' },
            ]"
        ></app-table>
    </div>
</template>

<script>
import { apiClient } from './../../services/apiClient.js';
import Table from './../Utils/Table.vue';

export default {
    data: function () {
        return {
            items: [],
        };
    },

    components: { 'app-table': Table },

    computed: {
        user() {
            return this.$store.state.auth.user;
        },

        cat: function () {
            return this.$route.params.category;
        },
    },

    watch: {
        async cat() {
            await this.fetchData();
        },
    },

    methods: {
        async fetchData() {
            this.items = await apiClient.getList(this.cat);
        },
    },

    async created() {
        await this.fetchData();
    },
};
</script>

<style scoped></style>
