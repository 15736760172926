<template>
    <div class="col-sm-12">
        <h2>Create a maintenance task</h2>

        <br />

        <table class="table">
            <tbody>
                <tr>
                    <td><strong>Equipment concerned</strong></td>
                    <td class="text-light">
                        <app-autocomplete-list
                            :user="user"
                            type="equipment"
                            :item="task"
                            propPath="equipment"
                        ></app-autocomplete-list>
                    </td>
                </tr>

                <tr>
                    <td><strong>Name</strong></td>
                    <td class="text-light">
                        <input
                            type="text"
                            class="form-control form-control-sm bg-dark text-light"
                            v-model="task.name"
                        />
                    </td>
                </tr>

                <tr>
                    <td><strong>Description</strong></td>
                    <td class="text-light">
                        <textarea
                            rows="5"
                            v-model="task.description"
                            class="form-control form-control-sm bg-dark text-light"
                        ></textarea>
                    </td>
                </tr>

                <tr>
                    <td><strong>Next due date</strong></td>
                    <td class="text-light">
                        <input
                            type="date"
                            class="form-control form-control-sm bg-dark text-light"
                            :value="task.dueDate && task.dueDate.split('T')[0]"
                            @input="task.dueDate = $event.target.value"
                        />
                    </td>
                </tr>

                <tr>
                    <td><strong>Regularity</strong></td>
                    <td class="text-light">
                        <input
                            type="number"
                            class="form-control form-control-sm bg-dark text-light"
                            v-model.number="task.regularity"
                        />
                    </td>
                </tr>
            </tbody>
        </table>

        <br />
        <div v-if="user.role > 3">
            <button
                type="button"
                class="btn btn-outline-success"
                @click="createTask()"
            >
                Create task
            </button>
        </div>
    </div>
</template>

<script>
import { apiClient } from './../../services/apiClient.js';
import { eventBus } from './../../services/eventBus.js';
import AutocompleteList from './../Utils/AutocompleteList.vue';

export default {
    data: function () {
        return {
            task: {},
        };
    },

    components: { 'app-autocomplete-list': AutocompleteList },

    computed: {
        user() {
            return this.$store.state.auth.user;
        },
    },

    methods: {
        async createTask() {
            let newTask = await apiClient.itemCreate(this.task, 'task');
            this.$router.push(newTask._id);
        },
    },

    async created() {
        eventBus.$on('update-list', async (item) => {
            this.task = item;
        });
    },
};
</script>

<style scoped>
@import './../../assets/css/single.css';
</style>
