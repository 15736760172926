import Vue from 'vue';

export const eventBus = new Vue({
    methods: {
        documentUpload() {
            this.$emit('document-upload');
        },

        updateList(item, key) {
            this.$emit('update-list', item, key);
        },

        updateItem(newItem) {
            this.$emit('update-item', newItem);
        },
    },
});
