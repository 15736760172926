import { store } from '@/store/store.js';

import axios from 'axios';
import Vue from 'vue';

axios.interceptors.response.use(function (res) {
    return res.data;
});

export const apiClient = new Vue({
    methods: {
        async login(credentials) {
            try {
                let res = await axios.post('/api/login', credentials);
                if (res.status === 'success') {
                    localStorage.setItem('user', JSON.stringify(res.data));
                    return res;
                } else store.commit('alert/error', res.message);
            } catch (e) {
                return e;
            }
        },

        async logout() {
            try {
                let res = await axios.get('/api/logout');
                return res;
            } catch (e) {
                return e;
            }
        },

        async requestResetToken(email) {
            try {
                let res = await axios.post('/api/user/reset-password', email);
                if (res.status === 'success') {
                    store.commit('alert/success', res.message);
                    return res.data;
                } else store.commit('alert/error', res.message);
            } catch (e) {
                return e;
            }
        },

        async updatePassword(id, token, password, confirmation) {
            if (password != confirmation)
                return store.commit('alert/error', "Passwords don' match.");
            if (password.length < 6)
                return store.commit('alert/error', 'Passwords too short.');

            try {
                let res = await axios.post(
                    '/api/user/' + id + '/password/' + token,
                    { password }
                );
                if (res.status === 'success') {
                    store.commit('alert/success', res.message);
                    return res.data.user;
                } else store.commit('alert/error', res.message);
            } catch (e) {
                return e;
            }
        },

        async itemCreate(item, type) {
            try {
                let res = await axios.post('/api/' + type + '/create', item);
                if (res.status === 'success')
                    store.commit('alert/success', res.message);
                else store.commit('alert/error', res.message);
                return res.data.item;
            } catch (e) {
                return e;
            }
        },

        async itemDelete(item, type) {
            try {
                let res = await axios.get(
                    '/api/' + type + '/' + item._id + '/delete/'
                );
                if (res.status === 'success') {
                    store.commit('alert/success', res.message);
                    return res.data.item;
                } else store.commit('alert/error', res.message);
            } catch (e) {
                return e;
            }
        },

        async itemUpdate(item, type) {
            try {
                let res = await axios.post(
                    '/api/' + type + '/' + item._id + '/update/',
                    item
                );
                if (res.status === 'success') {
                    store.commit('alert/success', res.message);
                    return res.data.item;
                } else store.commit('alert/error', res.message);
            } catch (e) {
                return e;
            }
        },

        async itemDocumentUpload(item, type, formData) {
            try {
                let res = await axios.post(
                    '/api/' + type + '/' + item._id + '/upload',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                if (res.status === 'success') {
                    store.commit('alert/success', res.message);
                    return res.data;
                } else store.commit('alert/error', res.message);
            } catch (e) {
                return e;
            }
        },

        async getList(type, attachedId) {
            let res;
            try {
                if (attachedId)
                    res = await axios.get(
                        '/api/' + type + '/list/' + attachedId
                    );
                else res = await axios.get('/api/' + type + '/list');
                if (res.status === 'success') {
                    return res.data.items;
                } else store.commit('alert/error', res.message);
            } catch (e) {
                return e;
            }
        },

        // async processReport(id) {
        //     try {
        //         let res = await axios.get('/api/report/process/' + id);
        //         if (res.status === 'success') {
        //             return res.data.item;
        //         } else store.commit('alert/error', res.message);
        //     } catch (e) {
        //         return e;
        //     }
        // },

        async getItem(id, type) {
            try {
                let res = await axios.get('/api/' + type + '/' + id);
                if (res.status === 'success') {
                    return res.data.item;
                } else store.commit('alert/error', res.message);
            } catch (e) {
                return e;
            }
        },

        async getReportByDate(date) {
            try {
                let res = await axios.get('/api/report/' + date);
                if (res.status === 'success') {
                    return res.data.item;
                }
            } catch (e) {
                return e;
            }
        },

        async getReportsByDateRange(startDate, endDate) {
            try {
                let res = await axios.get(
                    '/api/report/' + startDate + '/' + endDate
                );
                if (res.status === 'success') {
                    return res.data.items;
                }
            } catch (e) {
                return e;
            }
        },

        async getStatsByDateRange(startDate, endDate) {
            try {
                let res = await axios.get(
                    '/api/report/stats/' + startDate + '/' + endDate
                );
                if (res.status === 'success') {
                    return res.data;
                }
            } catch (e) {
                return e;
            }
        },

        async reProcessReport(date) {
            try {
                let res = await axios.get('/api/report/process/' + date);
                if (res.status === 'success') {
                    return res.data.item;
                }
            } catch (e) {
                return e;
            }
        },

        async getSettings() {
            try {
                let res = await axios.get('/api/settings/get');
                if (res.status === 'success') {
                    return res.data.settings;
                }
            } catch (e) {
                return e;
            }
        },
    },
});
