var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.items && _vm.items.length > 0)?_c('table',{staticClass:"table table-hover"},[_c('thead',[_c('tr',{staticClass:"__tableHead"},_vm._l((_vm.cols),function(col){return _c('th',{key:col.key},[_vm._v(" "+_vm._s(col.title)+" ")])}),0)]),_c('tbody',_vm._l((_vm.items),function(item){return _c('router-link',{key:item._id,class:{
                    __tableDanger:
                        item.status === 3 ||
                        (item.dueIn && item.dueIn < 1) ||
                        item.priority === 1,
                    __tableWarning:
                        item.status === 2 ||
                        (item.dueIn && item.dueIn > 0 && item.dueIn < 8) ||
                        item.priority === 2,
                    __tableSuccess:
                        _vm.cat !== 'equipment' && item.status === 1,
                    __tableInfo: _vm.cat === 'tickets' && !item.status,
                    __unverified:
                        (_vm.cat === 'user' && !item.verified) ||
                        ((_vm.cat === 'ticket' || _vm.cat === 'task') &&
                            item.status),
                },attrs:{"tag":"tr","to":item.path ? item.path : _vm.baseLink + item._id}},_vm._l((_vm.cols),function(col){return _c('td',{key:col.key},[(col.type === 'date')?[_vm._v(" "+_vm._s(col.key === 'wof' || col.key === 'dueDate' || col.key === 'registration' ? new Date( item[col.key] ).toLocaleDateString('en-NZ', { year: 'numeric', month: 'numeric', day: 'numeric', }) : new Date( item[col.key] ).toLocaleDateString('en-NZ', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', }))+" ")]:(col.type === 'role')?[_vm._v(" "+_vm._s(_vm.settings.userRole[item[col.key]])+" ")]:(col.type === 'priority')?[_vm._v(" "+_vm._s(_vm.settings.ticketPriority[item[col.key]])+" ")]:(col.type === 'location')?[_vm._v(" "+_vm._s(_vm.settings.location[item[col.key]])+" ")]:(col.type === 'status')?[_vm._v(" "+_vm._s(_vm.settings.equipmentStatus[item[col.key]])+" ")]:(col.type === 'type')?[_vm._v(" "+_vm._s(_vm.settings.equipmentType[item[col.key]])+" ")]:(
                            item[col.key] &&
                            (col.type === 'equipment' || col.type === 'by')
                        )?[_vm._v(" "+_vm._s(item[col.key].name)+" ")]:(col.type === 'history')?_vm._l((item.note),function(note){return _c('p',{key:note._id,staticClass:"__text-bloc"},[_vm._v(" "+_vm._s(note)),_c('br'),(item.ticket)?_c('a',{staticClass:"__link",attrs:{"target":"_blank"}},[_vm._v("See ticket details")]):_vm._e()])}):(col.type === 'link')?[_c('a',{staticClass:"__link __text-bloc",attrs:{"target":"_blank"}},[_vm._v(_vm._s(item[col.key]))])]:(col.type === 'document')?[_c('a',{attrs:{"target":"_blank"}},[_vm._v(_vm._s(item[col.key]))])]:(col.type === 'list')?[_c('ul',{staticClass:"__tableList"},_vm._l((item[col.key]),function(i){return _c('li',{key:i._id},[_vm._v(" "+_vm._s(i.name)+" ")])}),0)]:(col.type === 'number')?[_c('p',{staticClass:"__text-bloc"},[_vm._v(" "+_vm._s(item[col.key].toFixed(2))+" ")])]:[_c('p',{staticClass:"__text-bloc"},[_vm._v(" "+_vm._s(item[col.key])+" ")])]],2)}),0)}),1)]):_c('p',{staticClass:"__unverified"},[_vm._v("Nothing to see here.")])])}
var staticRenderFns = []

export { render, staticRenderFns }