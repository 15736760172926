import { render, staticRenderFns } from "./SQMView.vue?vue&type=template&id=2376bffc&scoped=true&"
import script from "./SQMView.vue?vue&type=script&lang=js&"
export * from "./SQMView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2376bffc",
  null
  
)

export default component.exports