<template>
    <div class="col-sm-12">
        <!-- Page title -->
        <h2>SQM readings</h2>
        <br />

        <!-- Add button -->
        <router-link
            tag="button"
            to="create"
            type="button"
            class="btn btn-outline-success"
        >
            New reading
        </router-link>

        <br />
        <br />

        <!-- Ticket list -->
        <app-table
            :category="'sqm'"
            :items="sqms"
            :cols="[
                { title: 'Average', key: 'average', type: 'number' },
                { title: 'Median', key: 'median', type: 'number' },
                { title: 'T°', key: 'temperature' },
                { title: 'note', key: 'note' },
                { title: 'Added', key: 'added', type: 'date' },
                { title: 'By', key: 'by', type: 'by' },
            ]"
        ></app-table>
    </div>
</template>

<script>
import { apiClient } from './../../services/apiClient.js';
import Table from './../Utils/Table.vue';

export default {
    data: function () {
        return {
            sqms: [],
        };
    },

    components: { 'app-table': Table },

    computed: {
        user() {
            return this.$store.state.auth.user;
        },
    },

    methods: {
        async fetchData() {
            this.sqms = await apiClient.getList('sqm');
        },
    },

    async created() {
        await this.fetchData();
    },
};
</script>

<style scoped></style>
