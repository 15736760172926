<template>
    <div class="container-fluid">
        <div v-if="!user" class="row" id="__login-row">
            <app-alerts></app-alerts>
            <app-password-reset
                v-if="$route.path.includes('password')"
            ></app-password-reset>
            <app-sqm-view v-if="$route.path.includes('sqmview')"></app-sqm-view>
            <app-login v-else></app-login>
        </div>
        <div v-else class="row" id="__main-row">
            <app-menu></app-menu>
            <main class="col-md-9 ml-sm-auto col-lg-10 px-4">
                <app-header></app-header>
                <app-alerts></app-alerts>
                <router-view></router-view>
            </main>
        </div>
    </div>
</template>

<script>
import Header from './components/Header.vue';
import Alerts from './components/Alerts.vue';
import Login from './components/Login.vue';
import Menu from './components/Menu.vue';
import PasswordReset from './components/PasswordReset.vue';
import SQMView from './components/SQM/SQMView.vue';

export default {
    components: {
        'app-header': Header,
        'app-alerts': Alerts,
        'app-login': Login,
        'app-menu': Menu,
        'app-password-reset': PasswordReset,
        'app-sqm-view': SQMView,
    },

    computed: {
        user() {
            return this.$store.state.auth.user;
        },
    },

    mounted() {
        this.$store.dispatch('settings/initSettings');
    },
};
</script>

<style>
@import './assets/css/main.css';
</style>
