import Vue from 'vue';
import Vuex from 'vuex';

import { auth } from '@/store/modules/auth.js';
import { alert } from '@/store/modules/alert.js';
import { settings } from '@/store/modules/settings.js';

Vue.use(Vuex);

export const store = new Vuex.Store({
    strict: true,
    modules: {
        auth,
        alert,
        settings,
    },
});
