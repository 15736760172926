var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-sm-12"},[_c('h2',[_vm._v("Equipment list ("+_vm._s(_vm.cat)+")")]),_c('br'),_c('router-link',{staticClass:"btn btn-outline-success",attrs:{"tag":"button","to":"create","type":"button"}},[_vm._v(" Add "+_vm._s(_vm.cat)+" ")]),_c('br'),_c('br'),(_vm.cat === 'facility')?_c('app-table',{attrs:{"category":'equipment',"items":_vm.items,"cols":[
            { title: 'Name', key: 'name' },
            { title: 'Description', key: 'description' },
            { title: 'Location', key: 'location', type: 'location' } ]}}):_vm._e(),(_vm.cat === 'vehicle')?_c('app-table',{attrs:{"category":'equipment',"items":_vm.items,"cols":[
            { title: 'Type', key: 'type', type: 'type' },
            { title: 'Name', key: 'name' },
            { title: 'License plate', key: 'description' },
            { title: 'WoF', key: 'wof', type: 'date' },
            {
                title: 'Registration',
                key: 'registration',
                type: 'date',
            } ]}}):_vm._e(),(_vm.cat === 'gear')?_c('app-table',{attrs:{"category":'equipment',"items":_vm.items,"cols":[
            { title: 'Type', key: 'type', type: 'type' },
            { title: 'Name', key: 'name' },
            { title: 'Description', key: 'description' },
            { title: 'Location', key: 'location', type: 'location' } ]}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }