<template>
    <div class="col-sm-12" v-if="Object.keys(equipment).length !== 0">
        <h2>{{ equipment.name }}</h2>
        <p class="__secondary">ID: {{ equipment._id }}</p>

        <br />

        <ul class="nav nav-tabs nav-justified md-tabs indigo" role="tablist">
            <!-- Details tab intro -->
            <li class="nav-item">
                <a
                    class="nav-link active"
                    data-toggle="tab"
                    href="#__details"
                    role="tab"
                    aria-controls="__details"
                    aria-selected="true"
                    ><strong>Details</strong></a
                >
            </li>

            <!-- History tab intro -->
            <li class="nav-item">
                <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#__history"
                    role="tab"
                    aria-controls="__history"
                    aria-selected="false"
                    ><strong>History</strong></a
                >
            </li>

            <!-- Documents tab intro -->
            <!-- <li class="nav-item">
                <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#__documents"
                    role="tab"
                    aria-controls="__documents"
                    aria-selected="false"
                    ><strong>Documents</strong></a
                >
            </li> -->

            <!-- Tickets tab intro -->
            <li class="nav-item">
                <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#__tickets"
                    role="tab"
                    aria-controls="__tickets"
                    aria-selected="false"
                    ><strong>Tickets</strong></a
                >
            </li>

            <!-- Tasks tab intro -->
            <li class="nav-item">
                <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#__tasks"
                    role="tab"
                    aria-controls="__tasks"
                    aria-selected="false"
                    ><strong>Tasks</strong></a
                >
            </li>
        </ul>

        <div class="tab-content card pt-5">
            <!-- Details tab -->
            <div
                class="tab-pane fade show active"
                id="__details"
                role="tabpanel"
            >
                <table class="table">
                    <tbody v-if="cat === 'facility'">
                        <tr>
                            <td><strong>Facility name</strong></td>
                            <td class="text-light">
                                <input
                                    type="text"
                                    class="form-control form-control-sm bg-dark text-light"
                                    v-model="equipment.name"
                                />
                            </td>
                        </tr>

                        <tr>
                            <td><strong>Description</strong></td>
                            <td class="text-light">
                                <textarea
                                    rows="5"
                                    v-model="equipment.description"
                                    class="form-control form-control-sm bg-dark text-light"
                                ></textarea>
                            </td>
                        </tr>

                        <tr>
                            <td><strong>Location</strong></td>
                            <td class="text-light">
                                <select
                                    id="location"
                                    class="form-control-sm bg-dark text-light"
                                    v-model="equipment.location"
                                >
                                    <option
                                        v-for="(
                                            location, key
                                        ) in settings.location"
                                        :key="key"
                                        :value="key"
                                    >
                                        {{ location }}
                                    </option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td><strong>Status</strong></td>
                            <td class="text-light">
                                <select
                                    id="location"
                                    class="form-control-sm bg-dark text-light"
                                    v-model="equipment.status"
                                >
                                    <option
                                        v-for="(
                                            status, key
                                        ) in settings.equipmentStatus"
                                        :key="key"
                                        :value="key"
                                    >
                                        {{ status }}
                                    </option>
                                </select>
                            </td>
                        </tr>
                    </tbody>

                    <tbody v-if="cat === 'vehicle'">
                        <tr>
                            <td><strong>Vehicle name</strong></td>
                            <td class="text-light">
                                <input
                                    type="text"
                                    class="form-control form-control-sm bg-dark text-light"
                                    v-model="equipment.name"
                                />
                            </td>
                        </tr>

                        <tr>
                            <td><strong>Type</strong></td>
                            <td class="text-light">
                                <select
                                    id="type"
                                    class="form-control-sm bg-dark text-light"
                                    v-model="equipment.type"
                                >
                                    <option
                                        v-for="(
                                            type, key
                                        ) in settings.vehicleType"
                                        :key="key"
                                        :value="key"
                                    >
                                        {{ type }}
                                    </option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td><strong>License plate</strong></td>
                            <td class="text-light">
                                <input
                                    type="text"
                                    class="form-control form-control-sm bg-dark text-light"
                                    v-model="equipment.description"
                                />
                            </td>
                        </tr>

                        <tr>
                            <td><strong>WoF expires</strong></td>
                            <td class="text-light">
                                <input
                                    type="date"
                                    class="form-control form-control-sm bg-dark text-light"
                                    :value="
                                        equipment.wof &&
                                        equipment.wof.split('T')[0]
                                    "
                                    @input="equipment.wof = $event.target.value"
                                />
                            </td>
                        </tr>

                        <tr>
                            <td><strong>Registration expires</strong></td>
                            <td class="text-light">
                                <input
                                    type="date"
                                    class="form-control form-control-sm bg-dark text-light"
                                    :value="
                                        equipment.registration &&
                                        equipment.registration.split('T')[0]
                                    "
                                    @input="
                                        equipment.registration =
                                            $event.target.value
                                    "
                                />
                            </td>
                        </tr>

                        <tr>
                            <td><strong>Status</strong></td>
                            <td class="text-light">
                                <select
                                    id="location"
                                    class="form-control-sm bg-dark text-light"
                                    v-model="equipment.status"
                                >
                                    <option
                                        v-for="(
                                            status, key
                                        ) in settings.equipmentStatus"
                                        :key="key"
                                        :value="key"
                                    >
                                        {{ status }}
                                    </option>
                                </select>
                            </td>
                        </tr>
                    </tbody>

                    <tbody v-if="cat === 'gear'">
                        <tr>
                            <td><strong>Equipment name</strong></td>
                            <td class="text-light">
                                <input
                                    type="text"
                                    class="form-control form-control-sm bg-dark text-light"
                                    v-model="equipment.name"
                                />
                            </td>
                        </tr>

                        <tr>
                            <td><strong>Type</strong></td>
                            <td class="text-light">
                                <select
                                    id="type"
                                    class="form-control-sm bg-dark text-light"
                                    v-model="equipment.type"
                                >
                                    <option
                                        v-for="(
                                            type, key
                                        ) in settings.equipmentType"
                                        :key="key"
                                        :value="key"
                                    >
                                        {{ type }}
                                    </option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td><strong>Description</strong></td>
                            <td class="text-light">
                                <textarea
                                    rows="5"
                                    class="form-control form-control-sm bg-dark text-light"
                                    v-model="equipment.description"
                                />
                            </td>
                        </tr>

                        <tr>
                            <td><strong>Location</strong></td>
                            <td class="text-light">
                                <select
                                    id="location"
                                    class="form-control-sm bg-dark text-light"
                                    v-model="equipment.location"
                                >
                                    <option
                                        v-for="(
                                            location, key
                                        ) in settings.location"
                                        :key="key"
                                        :value="key"
                                    >
                                        {{ location }}
                                    </option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td><strong>Status</strong></td>
                            <td class="text-light">
                                <select
                                    id="location"
                                    class="form-control-sm bg-dark text-light"
                                    v-model="equipment.status"
                                >
                                    <option
                                        v-for="(
                                            status, key
                                        ) in settings.equipmentStatus"
                                        :key="key"
                                        :value="key"
                                    >
                                        {{ status }}
                                    </option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- History tab -->
            <div class="tab-pane fade" id="__history" role="tabpanel">
                <app-table
                    :items="equipment.history"
                    :cols="[
                        { title: 'Note', key: 'note', type: 'history' },
                        { title: 'By', key: 'by', type: 'by' },
                        { title: 'Date', key: 'date', type: 'date' },
                    ]"
                ></app-table>
            </div>

            <!-- Documents tab -->
            <!-- <div class="tab-pane fade" id="__documents" role="tabpanel">
                <app-table
                    :items="equipment.documents"
                    :cols="[
                        { title: 'Name', key: 'name', type: 'document' },
                        { title: 'Type', key: 'mimetype' },
                        { title: 'Added', key: 'added', type: 'date' }
                    ]"
                ></app-table>
                <table class="table">
                    <tbody>
                        <tr>
                            <td>
                                <strong>Add document</strong>
                            </td>
                            <td class="text-light">
                                <div id="__addDocumentForm" class="text-light">
                                    <br />
                                    <label
                                        for="file"
                                        class="btn btn-outline-info"
                                    >
                                        <input
                                            type="file"
                                            id="file"
                                            ref="file"
                                            @change="file = $refs.file.files[0]"
                                            class="form-control form-control-sm bg-dark text-light"
                                        />
                                        Browse for a file
                                    </label>
                                    &nbsp;&nbsp;{{ file.name }}
                                    <br />
                                    <button
                                        type="button"
                                        class="btn btn-outline-success"
                                        @click="uploadDocument()"
                                    >
                                        Upload document
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> -->

            <!-- Tickets tab -->
            <div class="tab-pane fade" id="__tickets" role="tabpanel">
                <app-table
                    :category="'tickets'"
                    :items="tickets"
                    :cols="[
                        {
                            title: 'Description',
                            key: 'description',
                            type: 'link',
                        },
                        { title: 'By', key: 'by', type: 'by' },
                        { title: 'Added', key: 'added', type: 'date' },
                    ]"
                ></app-table>
            </div>

            <!-- Tasks tab -->
            <div class="tab-pane fade" id="__tasks" role="tabpanel">
                <app-table
                    :category="'tasks'"
                    :items="tasks"
                    :cols="[
                        { title: 'Name', key: 'name', type: 'link' },
                        {
                            title: 'regularity',
                            key: 'regularity',
                            type: 'number',
                        },
                        {
                            title: 'Next due date',
                            key: 'dueDate',
                            type: 'date',
                        },
                    ]"
                ></app-table>
            </div>
        </div>

        <br />
        <br />

        <!-- Buttons -->
        <div v-if="user.role > 2">
            <button
                type="button"
                class="btn btn-outline-success"
                @click="updateEquipment()"
            >
                Save changes
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
                type="button"
                class="btn btn-outline-danger"
                @click="deleteEquipment()"
            >
                Delete equipment
            </button>
        </div>
    </div>
</template>

<script>
import { eventBus } from './../../services/eventBus.js';
import { apiClient } from './../../services/apiClient.js';
import Table from './../Utils/Table.vue';

export default {
    data: function () {
        return {
            equipment: {},
            tasks: [],
            tickets: [],
            showAddDocument: false,
            file: '',
        };
    },

    components: { 'app-table': Table },

    computed: {
        user() {
            return this.$store.state.auth.user;
        },

        settings() {
            return this.$store.state.settings.settings;
        },

        cat: function () {
            return this.$route.params.category;
        },
    },

    watch: {
        cat: async function () {
            await this.fetchData();
        },
    },

    methods: {
        async updateEquipment() {
            await apiClient.itemUpdate(this.equipment, 'equipment');
            await this.fetchData();
        },

        async deleteEquipment() {
            if (await apiClient.itemDelete(this.equipment, 'equipment')) {
                this.equipment = {};
                this.$router.push('list');
            }
        },

        // async uploadDocument() {
        //     let formData = new FormData();
        //     formData.append('file', this.file);
        //     await apiClient.itemDocumentUpload(
        //         this.equipment,
        //         'equipment',
        //         formData
        //     );
        //     await this.fetchData();
        // },

        // async removeDocument(document) {
        //     const documentList = this.equipment.documents.filter((item) => {
        //         return item.name != document.name;
        //     });
        //     this.equipment.documents = documentList;
        //     await apiClient.itemUpdate(this.equipment, 'equipment');
        // },

        async fetchData() {
            this.equipment = await apiClient.getItem(
                this.$route.params.id,
                this.cat
            );
            this.tasks = await apiClient.getList('task', this.equipment._id);
            this.tickets = await apiClient.getList(
                'ticket',
                this.equipment._id
            );
        },
    },

    async created() {
        await this.fetchData();

        eventBus.$on('document-upload', async () => {
            await this.fetchData();
        });
    },
};
</script>

<style scoped>
@import './../../assets/css/single.css';
</style>
