<template>
    <div class="col-sm-12" v-if="settings">
        <h2>{{ date }} | Night report</h2>

        <br />

        <ul class="nav nav-tabs nav-justified md-tabs indigo" role="tablist">
            <!-- Setup tab intro -->
            <li class="nav-item">
                <a
                    class="nav-link active"
                    data-toggle="tab"
                    href="#__setup"
                    role="tab"
                    aria-controls="__setup"
                    aria-selected="true"
                    ><strong>Setup</strong></a
                >
            </li>

            <!-- Reception tab intro -->
            <li class="nav-item">
                <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#__reception"
                    role="tab"
                    aria-controls="__reception"
                    aria-selected="true"
                    ><strong>Reception</strong></a
                >
            </li>

            <!-- Tours tab intro -->
            <li class="nav-item">
                <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#__tours"
                    role="tab"
                    aria-controls="__tours"
                    aria-selected="false"
                    ><strong>Tours</strong></a
                >
            </li>
        </ul>

        <div class="tab-content card pt-5">
            <!-- Setup tab -->
            <div class="tab-pane fade show active" id="__setup" role="tabpanel">
                <h4>Team</h4>
                <table class="table" v-if="item.team">
                    <tbody>
                        <tr>
                            <td>
                                <strong>Ops Leader</strong>
                            </td>
                            <td>
                                <input
                                    type="text"
                                    class="form-control form-control-sm bg-dark text-light"
                                    v-model="item.team.opsLeader"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Receptionists</strong>
                            </td>
                            <td>
                                <input
                                    type="text"
                                    class="form-control form-control-sm bg-dark text-light"
                                    v-model="item.team.customerService"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Mount John drivers</strong>
                            </td>
                            <td>
                                <input
                                    type="text"
                                    class="form-control form-control-sm bg-dark text-light"
                                    v-model="item.team.mjDrivers"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Cowan's drivers</strong>
                            </td>
                            <td>
                                <input
                                    type="text"
                                    class="form-control form-control-sm bg-dark text-light"
                                    v-model="item.team.cwDrivers"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br />
                <br />

                <h4>Weather forecast</h4>
                <table class="table" v-if="item.forecast">
                    <tbody>
                        <tr>
                            <td>
                                <strong>Early night</strong>
                            </td>
                            <td>
                                <input
                                    type="range"
                                    class="form-control-range"
                                    v-model="item.forecast.evalEarly"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Late night</strong>
                            </td>
                            <td>
                                <input
                                    type="range"
                                    class="form-control-range"
                                    v-model="item.forecast.evalLate"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Notes</strong>
                            </td>
                            <td>
                                <textarea
                                    rows="5"
                                    class="form-control form-control-sm bg-dark text-light"
                                    v-model="item.forecast.note"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Night status</strong>
                            </td>
                            <td>
                                <select
                                    id="night-status"
                                    class="form-control-sm bg-dark text-light"
                                    v-model.number="item.nightStatus"
                                >
                                    <option
                                        v-for="(
                                            type, key
                                        ) in settings.nightStatus"
                                        :key="key"
                                        :value="key"
                                    >
                                        {{ type }}
                                    </option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br />
                <br />

                <h4>Tours</h4>
                <table class="table" v-if="item.tours && item.tours.length > 0">
                    <thead>
                        <tr class="__tableHead">
                            <th>Tour name</th>
                            <th>Type</th>
                            <th>Language</th>
                            <th>Capacity</th>
                            <th>Booked</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(tour, index) in item.tours" :key="index">
                            <td style="width: 50%">
                                <input
                                    type="text"
                                    class="form-control form-control-sm bg-dark text-light"
                                    v-model="tour.name"
                                />
                            </td>
                            <td>
                                <select
                                    id="location"
                                    class="form-control-sm bg-dark text-light"
                                    v-model.number="tour.tourType"
                                >
                                    <option
                                        v-for="(type, key) in settings.tourType"
                                        :key="key"
                                        :value="key"
                                    >
                                        {{ type }}
                                    </option>
                                </select>
                            </td>
                            <td>
                                <select
                                    id="language"
                                    class="form-control-sm bg-dark text-light"
                                    v-model.number="tour.language"
                                >
                                    <option
                                        v-for="(type, key) in settings.language"
                                        :key="key"
                                        :value="key"
                                    >
                                        {{ type }}
                                    </option>
                                </select>
                            </td>
                            <td>
                                <input
                                    type="number"
                                    class="form-control form-control-sm bg-dark text-light"
                                    v-model.number="tour.capacity"
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    class="form-control form-control-sm bg-dark text-light"
                                    v-model.number="tour.paxBooked"
                                />
                            </td>
                            <td>
                                <i
                                    class="fas fa-times"
                                    @click="removeTour(tour)"
                                ></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <button
                    type="button"
                    class="btn btn-outline-info"
                    @click="addTour()"
                >
                    Add Tour
                </button>
            </div>

            <!-- Reception tab -->
            <div class="tab-pane fade" id="__reception" role="tabpanel">
                <table class="table" v-if="item.tours && item.tours.length > 0">
                    <thead>
                        <tr class="__tableHead">
                            <th>Tour (capacity)</th>
                            <th>Status</th>
                            <th>Options</th>
                            <th>Booked</th>
                            <th>Joined</th>
                            <th>Transfers</th>
                            <th>Open dated</th>
                            <th>Refunds</th>
                            <th>No-show</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(tour, index) in item.tours" :key="index">
                            <td class="nowrap">
                                {{ tour.name }} -
                                {{ settings.language[tour.language] }}
                                ({{ tour.capacity }})
                            </td>
                            <td>
                                <select
                                    id="tour-status"
                                    class="form-control-sm bg-dark text-light"
                                    v-model.number="tour.status"
                                >
                                    <option
                                        v-for="(
                                            type, key
                                        ) in settings.tourStatus"
                                        :key="key"
                                        :value="key"
                                    >
                                        {{ type }}
                                    </option>
                                </select>
                            </td>
                            <td class="text-light">
                                <div class="custom-control custom-switch">
                                    <input
                                        type="checkbox"
                                        :id="tour._id"
                                        class="custom-control-input"
                                        v-model="tour.options"
                                    />
                                    <label
                                        class="custom-control-label"
                                        :for="tour._id"
                                    ></label>
                                </div>
                            </td>
                            <td>
                                <input
                                    type="number"
                                    class="form-control form-control-sm bg-dark text-light"
                                    v-model.number="tour.paxBooked"
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    class="form-control form-control-sm bg-dark text-light"
                                    v-model.number="tour.paxJoined"
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    class="form-control form-control-sm bg-dark text-light"
                                    v-model.number="tour.transfers"
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    class="form-control form-control-sm bg-dark text-light"
                                    v-model.number="tour.openDated"
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    class="form-control form-control-sm bg-dark text-light"
                                    v-model.number="tour.refunds"
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    class="form-control form-control-sm bg-dark text-light"
                                    v-model.number="tour.noShow"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="table" v-if="item.team">
                    <tbody>
                        <tr>
                            <td>
                                <strong>Note</strong>
                            </td>
                            <td>
                                <textarea
                                    rows="5"
                                    class="form-control form-control-sm bg-dark text-light"
                                    v-model="item.officeNote"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Tours tab -->
            <div class="tab-pane fade" id="__tours" role="tabpanel">
                <div v-for="(tour, index) in item.tours" :key="index">
                    <div :class="{ __greyed: tour.status === 1 }">
                        <h4>
                            {{ tour.name }} -
                            {{ settings.language[tour.language] }}
                        </h4>
                        <p class="__secondary">
                            Capacity: {{ tour.capacity }} | Booked:
                            {{ tour.paxBooked }} | Joined:
                            {{ tour.paxJoined }} | No-show: {{ tour.noShow }}
                        </p>
                        <table class="table" v-if="item.team">
                            <tbody :class="{ __greyed: tour.status === 1 }">
                                <tr>
                                    <td>
                                        <strong>Tour status</strong>
                                    </td>
                                    <td>
                                        <select
                                            id="tour-status"
                                            class="form-control-sm bg-dark text-light"
                                            v-model.number="tour.status"
                                        >
                                            <option
                                                v-for="(
                                                    type, key
                                                ) in settings.tourStatus"
                                                :key="key"
                                                :value="key"
                                            >
                                                {{ type }}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                                <tr v-if="tour.status != 1">
                                    <td>
                                        <strong>Weather quality</strong>
                                    </td>
                                    <td>
                                        <input
                                            type="range"
                                            class="form-control-range"
                                            v-model="tour.weather.eval"
                                        />
                                    </td>
                                </tr>
                                <tr v-if="tour.status != 1">
                                    <td>
                                        <strong>Notes</strong>
                                    </td>
                                    <td>
                                        <textarea
                                            rows="5"
                                            class="form-control form-control-sm bg-dark text-light"
                                            v-model="tour.note"
                                        />
                                    </td>
                                </tr>
                                <tr v-if="tour.status != 1">
                                    <td>
                                        <strong>Guide</strong>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            class="form-control form-control-sm bg-dark text-light"
                                            v-model="tour.guides"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr class="__sep" />
                    </div>
                </div>
            </div>
        </div>

        <br />
        <br />

        <!-- Buttons -->
        <div v-if="user.role > 2">
            <button
                type="button"
                class="btn btn-outline-success"
                @click="saveItem()"
            >
                Save changes
            </button>
        </div>
    </div>
</template>

<script>
import { eventBus } from './../../services/eventBus.js';
import { apiClient } from './../../services/apiClient.js';

export default {
    data: function () {
        return {
            item: {},
        };
    },

    computed: {
        user() {
            return this.$store.state.auth.user;
        },

        settings() {
            return this.$store.state.settings.settings;
        },

        date: function () {
            if (this.$route.params.date) return this.$route.params.date;
            else {
                let now = new Date();
                if (now.getHours() < 6) now.setDate(now.getDate() - 1);
                let dateDay =
                    now.getDate() < 10 ? '0' + now.getDate() : now.getDate();
                let dateMonth =
                    now.getMonth() + 1 < 10
                        ? '0' + (now.getMonth() + 1)
                        : now.getMonth() + 1;
                return now.getFullYear() + '-' + dateMonth + '-' + dateDay;
            }
        },
    },

    watch: {
        async date() {
            await this.fetchData();
        },
    },

    methods: {
        async saveItem() {
            await apiClient.itemUpdate(this.item, 'report');
            await this.fetchData();
        },

        async fetchData() {
            this.item = await apiClient.getReportByDate(this.date);
            if (!this.item)
                this.item = await apiClient.itemCreate(
                    { date: this.date },
                    'report'
                );
        },

        async addTour() {
            this.item.tours.push({});
            await this.saveItem();
        },

        async removeTour(tour) {
            this.item.tours = this.item.tours.filter((i) => {
                return i._id != tour._id;
            });
        },
    },

    async created() {
        await this.fetchData();

        eventBus.$on('update-item', async (newItem) => {
            this.item = await newItem;
        });
    },
};
</script>

<style scoped>
@import './../../assets/css/single.css';
</style>
