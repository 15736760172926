<template>
    <div>
        <table class="table table-hover" v-if="items && items.length > 0">
            <thead>
                <tr class="__tableHead">
                    <th v-for="col in cols" :key="col.key">
                        {{ col.title }}
                    </th>
                </tr>
            </thead>

            <tbody>
                <router-link
                    tag="tr"
                    v-for="item in items"
                    :key="item._id"
                    :to="item.path ? item.path : baseLink + item._id"
                    :class="{
                        __tableDanger:
                            item.status === 3 ||
                            (item.dueIn && item.dueIn < 1) ||
                            item.priority === 1,
                        __tableWarning:
                            item.status === 2 ||
                            (item.dueIn && item.dueIn > 0 && item.dueIn < 8) ||
                            item.priority === 2,
                        __tableSuccess:
                            cat !== 'equipment' && item.status === 1,
                        __tableInfo: cat === 'tickets' && !item.status,
                        __unverified:
                            (cat === 'user' && !item.verified) ||
                            ((cat === 'ticket' || cat === 'task') &&
                                item.status),
                    }"
                >
                    <td v-for="col in cols" :key="col.key">
                        <template v-if="col.type === 'date'">
                            {{
                                col.key === 'wof' ||
                                col.key === 'dueDate' ||
                                col.key === 'registration'
                                    ? new Date(
                                          item[col.key]
                                      ).toLocaleDateString('en-NZ', {
                                          year: 'numeric',
                                          month: 'numeric',
                                          day: 'numeric',
                                      })
                                    : new Date(
                                          item[col.key]
                                      ).toLocaleDateString('en-NZ', {
                                          year: 'numeric',
                                          month: 'numeric',
                                          day: 'numeric',
                                          hour: 'numeric',
                                          minute: 'numeric',
                                      })
                            }}
                        </template>

                        <template v-else-if="col.type === 'role'">
                            {{ settings.userRole[item[col.key]] }}
                        </template>

                        <template v-else-if="col.type === 'priority'">
                            {{ settings.ticketPriority[item[col.key]] }}
                        </template>

                        <template v-else-if="col.type === 'location'">
                            {{ settings.location[item[col.key]] }}
                        </template>

                        <template v-else-if="col.type === 'status'">
                            {{ settings.equipmentStatus[item[col.key]] }}
                        </template>

                        <template v-else-if="col.type === 'type'">
                            {{ settings.equipmentType[item[col.key]] }}
                        </template>

                        <template
                            v-else-if="
                                item[col.key] &&
                                (col.type === 'equipment' || col.type === 'by')
                            "
                        >
                            {{ item[col.key].name }}
                        </template>

                        <template v-else-if="col.type === 'history'">
                            <p
                                class="__text-bloc"
                                v-for="note in item.note"
                                :key="note._id"
                            >
                                {{ note }}<br />
                                <a
                                    v-if="item.ticket"
                                    class="__link"
                                    target="_blank"
                                    >See ticket details</a
                                >
                            </p>
                        </template>

                        <template v-else-if="col.type === 'link'">
                            <a class="__link __text-bloc" target="_blank">{{
                                item[col.key]
                            }}</a>
                        </template>

                        <template v-else-if="col.type === 'document'">
                            <a target="_blank">{{ item[col.key] }}</a>
                        </template>

                        <template v-else-if="col.type === 'list'">
                            <ul class="__tableList">
                                <li v-for="i in item[col.key]" :key="i._id">
                                    {{ i.name }}
                                </li>
                            </ul>
                        </template>
                        <template v-else-if="col.type === 'number'">
                            <p class="__text-bloc">
                                {{ item[col.key].toFixed(2) }}
                            </p>
                        </template>
                        <template v-else>
                            <p class="__text-bloc">
                                {{ item[col.key] }}
                            </p>
                        </template>
                    </td>
                </router-link>
            </tbody>
        </table>
        <p v-else class="__unverified">Nothing to see here.</p>
    </div>
</template>

<script>
export default {
    data: function () {
        return {};
    },

    props: {
        items: Array,
        cols: Array,
        category: String,
    },

    computed: {
        user() {
            return this.$store.state.auth.user;
        },

        settings() {
            return this.$store.state.settings.settings;
        },

        cat: function () {
            // Need cat for list colors (user, task, ticket)
            return this.category;
        },

        baseLink: function () {
            if (this.cat === 'tasks') return '/maintenance/task/';
            else if (this.cat === 'tickets') return '/maintenance/ticket/';
            else return '';
        },
    },
};
</script>

<style scoped>
@import './../../assets/css/list.css';
@import './../../assets/css/tables.css';
</style>
