import { apiClient } from '@/services/apiClient.js';

export const settings = {
    namespaced: true,

    state: {
        loaded: false,
        settings: null,
    },

    actions: {
        async initSettings({ commit }) {
            const settingList = await apiClient.getSettings();
            commit('populateSettings', settingList);
        },
    },

    mutations: {
        populateSettings(state, settings) {
            state.settings = settings;
        },
    },
};
