import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import ReportCreate from './../components/Report/ReportCreate.vue';
import ReportDetails from './../components/Report/ReportDetails.vue';

import TaskDetails from './../components/Task/TaskDetails.vue';
import TaskCreate from './../components/Task/TaskCreate.vue';
import TaskList from './../components/Task/TaskList.vue';

import TicketDetails from './../components/Ticket/TicketDetails.vue';
import TicketCreate from './../components/Ticket/TicketCreate.vue';
import TicketList from './../components/Ticket/TicketList.vue';

import EquipmentDetails from './../components/Equipment/EquipmentDetails.vue';
import EquipmentCreate from './../components/Equipment/EquipmentCreate.vue';
import EquipmentList from './../components/Equipment/EquipmentList.vue';

import UserDetails from './../components/User/UserDetails.vue';
import UserCreate from './../components/User/UserCreate.vue';
import UserList from './../components/User/UserList.vue';

import SQMDetails from './../components/SQM/SQMDetails.vue';
import SQMList from './../components/SQM/SQMList.vue';

import PasswordReset from './../components/PasswordReset.vue';
import Login from './../components/Login.vue';
import Home from './../components/Home.vue';
import Stats from './../components/Tools/Stats.vue';
import SSData from './../components/Tools/SSData.vue';

export const router = new Router({
    routes: [
        {
            path: '/login',
            component: Login,
        },

        {
            path: '/stats',
            component: Stats,
        },

        {
            path: '/ssdata',
            component: SSData,
        },

        {
            path: '/user/list',
            component: UserList,
        },

        {
            path: '/user/create',
            component: UserCreate,
        },

        {
            path: '/user/:id',
            component: UserDetails,
        },

        {
            path: '/user/:id/password/:token',
            component: PasswordReset,
        },

        {
            path: '/report',
            component: ReportCreate,
            props: { category: 'report' },
        },

        {
            path: '/report/view',
            component: ReportDetails,
            props: { category: 'report' },
        },

        {
            path: '/report/view/:date',
            component: ReportDetails,
            props: { category: 'report' },
        },

        {
            path: '/report/:date',
            component: ReportCreate,
            props: { category: 'report' },
        },

        {
            path: '/maintenance/task/list',
            component: TaskList,
        },

        {
            path: '/maintenance/task/create',
            component: TaskCreate,
        },

        {
            path: '/maintenance/task/:id',
            component: TaskDetails,
        },

        {
            path: '/maintenance/ticket/list',
            component: TicketList,
        },

        {
            path: '/maintenance/ticket/create',
            component: TicketCreate,
        },

        {
            path: '/maintenance/ticket/:id',
            component: TicketDetails,
        },

        {
            path: '/equipment/:category/list',
            component: EquipmentList,
        },

        {
            path: '/equipment/:category/create',
            component: EquipmentCreate,
        },

        {
            path: '/equipment/:category/:id',
            component: EquipmentDetails,
        },

        {
            path: '/equipment/:id',
            component: EquipmentDetails,
        },

        {
            path: '/sqm/list',
            component: SQMList,
        },

        {
            path: '/sqm/:id',
            component: SQMDetails,
        },

        {
            path: '/',
            component: Home,
        },
    ],
    mode: 'history',
});

// router.beforeEach((to, from, next) => {
//     const publicPages = ['/login', '/auth'];
//     const authRequired = !publicPages.includes(to.path);
//     const loggedIn = localStorage.getItem('user');
//     if (authRequired && !to.params.token && !loggedIn) {
//         return next('/login');
//     }
//     next();
// });
