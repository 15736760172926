<template>
    <div class="col-sm-12">
        <h2>Task details</h2>
        <p class="__secondary">ID: {{ task._id }}</p>

        <br />

        <table class="table">
            <tbody>
                <tr>
                    <td><strong>Equipment</strong></td>
                    <td class="text-light">
                        <app-autocomplete-list
                            :user="user"
                            type="equipment"
                            :item="task"
                            propPath="equipment"
                        ></app-autocomplete-list>
                    </td>
                </tr>

                <tr>
                    <td><strong>History</strong></td>
                    <td class="text-light">
                        <table class="table text-light">
                            <thead>
                                <tr class="__tableHead">
                                    <th>Note</th>
                                    <th>By</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(el, index) in task.history"
                                    :key="el._id"
                                    :class="{
                                        __tableSuccess: el.status,
                                        __tableInfo:
                                            index === task.history.length - 1,
                                    }"
                                >
                                    <td>
                                        <p
                                            class="__text-bloc"
                                            v-for="note in el.note"
                                            :key="note"
                                        >
                                            {{ note }}
                                        </p>
                                    </td>
                                    <td>{{ el.by.name }}</td>
                                    <td>
                                        <app-date :value="el.date"></app-date>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td><strong>Add note</strong></td>
                    <td class="text-light">
                        <textarea
                            rows="5"
                            v-model="task.note"
                            class="form-control form-control-sm bg-dark text-light"
                        ></textarea>
                    </td>
                </tr>

                <tr>
                    <td><strong>Next due date</strong></td>
                    <td class="text-light">
                        <input
                            type="date"
                            class="form-control form-control-sm bg-dark text-light"
                            :value="task.dueDate && task.dueDate.split('T')[0]"
                            @input="task.dueDate = $event.target.value"
                        />
                    </td>
                </tr>

                <tr>
                    <td><strong>Regularity</strong></td>
                    <td class="text-light">
                        <input
                            type="number"
                            class="form-control form-control-sm bg-dark text-light"
                            v-model.number="task.regularity"
                        />
                    </td>
                </tr>

                <tr>
                    <td><strong>Done</strong></td>
                    <td class="text-light">
                        <div class="custom-control custom-switch">
                            <input
                                type="checkbox"
                                class="custom-control-input"
                                v-model="task.status"
                                id="taskStatus"
                            />
                            <label
                                class="custom-control-label"
                                for="taskStatus"
                            ></label>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td><strong>Added on</strong></td>
                    <td class="text-light">
                        <app-date :value="task.added"></app-date>
                    </td>
                </tr>

                <tr>
                    <td><strong>Updated on</strong></td>
                    <td class="text-light">
                        <app-date :value="task.updated"></app-date>
                    </td>
                </tr>
            </tbody>
        </table>

        <br />
        <br />

        <!-- Buttons -->
        <div v-if="user.role > 2">
            <button
                type="button"
                class="btn btn-outline-success"
                @click="updateTask()"
            >
                Save task
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
                type="button"
                class="btn btn-outline-danger"
                @click="deleteTask()"
            >
                Delete task
            </button>
        </div>
    </div>
</template>

<script>
import { eventBus } from './../../services/eventBus.js';
import { apiClient } from './../../services/apiClient.js';
import AutocompleteList from './../Utils/AutocompleteList.vue';
import DateDisplay from './../Utils/Date.vue';

export default {
    data: function () {
        return {
            task: {},
        };
    },

    components: {
        'app-autocomplete-list': AutocompleteList,
        'app-date': DateDisplay,
    },

    computed: {
        user() {
            return this.$store.state.auth.user;
        },
    },

    methods: {
        async updateTask() {
            await apiClient.itemUpdate(this.task, 'task');
            await this.fetchData();
        },

        async deleteTask() {
            if (await apiClient.itemDelete(this.task, 'task')) {
                this.task = {};
                this.$router.push('list');
            }
        },

        async fetchData() {
            this.task = await apiClient.getItem(this.$route.params.id, 'task');
        },
    },

    async created() {
        await this.fetchData();

        eventBus.$on('update-list', async (item) => {
            this.task = item;
        });
    },
};
</script>

<style scoped>
@import './../../assets/css/single.css';
</style>
