<template>
    <div class="col-sm-12">
        <h2>Solar system data</h2>
        <br />
        This script shows what Solar system objects (planets) will be visible on
        a given tour.
        <br />
        <br />
        <form class="form-inline">
            <label for="date" style="margin-right: 1em;">Date:</label>
            <input
                type="date"
                class="input-lg form-control form-control-sm bg-dark text-light text-center"
                :value="date"
                style="display: inline"
            />
        </form>
        <br />
        <button
            type="button"
            class="btn btn-outline-success"
            @click="getSSData()"
        >
            Get data
        </button>
    </div>
</template>

<script>
export default {
    data: function() {
        return {};
    },

    computed: {
        date() {
            if (this.$route.params.date) return this.$route.params.date;
            else {
                let now = new Date();
                now.setDate(now.getDate() - 1);
                let dateDay =
                    now.getDate() < 10 ? '0' + now.getDate() : now.getDate();
                let dateMonth =
                    now.getMonth() + 1 < 10
                        ? '0' + (now.getMonth() + 1)
                        : now.getMonth() + 1;
                return now.getFullYear() + '-' + dateMonth + '-' + dateDay;
            }
        }
    },

    methods: {}
};
</script>

<style scoped></style>
