<template>
    <div class="col-sm-12">
        <h2>Open a maintenance ticket</h2>

        <br />

        <table class="table">
            <tbody>
                <tr>
                    <td><strong>Equipment concerned</strong></td>
                    <td class="text-light">
                        <app-autocomplete-list
                            :user="user"
                            type="equipment"
                            :item="ticket"
                            propPath="equipment"
                        ></app-autocomplete-list>
                    </td>
                </tr>

                <tr>
                    <td><strong>Description</strong></td>
                    <td class="text-light">
                        <textarea
                            rows="5"
                            v-model="ticket.description"
                            class="form-control form-control-sm bg-dark text-light"
                        ></textarea>
                    </td>
                </tr>

                <tr>
                    <td>
                        <strong>Priority</strong> <i>(leave blank if unsure)</i>
                    </td>
                    <td class="text-light">
                        <select
                            id="priority"
                            class="form-control-sm bg-dark text-light"
                            v-model="ticket.priority"
                        >
                            <option
                                v-for="(
                                    ticketPriority, key
                                ) in settings.ticketPriority"
                                :key="key"
                                :value="key"
                            >
                                {{ ticketPriority }}
                            </option>
                        </select>
                    </td>
                </tr>
            </tbody>
        </table>

        <br />
        <div v-if="user.role > 3">
            <button
                type="button"
                class="btn btn-outline-success"
                @click="createTicket()"
            >
                Open ticket
            </button>
        </div>
    </div>
</template>

<script>
import { apiClient } from './../../services/apiClient.js';
import { eventBus } from './../../services/eventBus.js';
import AutocompleteList from './../Utils/AutocompleteList.vue';

export default {
    data: function () {
        return {
            ticket: {},
        };
    },

    components: { 'app-autocomplete-list': AutocompleteList },

    computed: {
        user() {
            return this.$store.state.auth.user;
        },

        settings() {
            return this.$store.state.settings.settings;
        },
    },

    methods: {
        async createTicket() {
            let newTicket = await apiClient.itemCreate(this.ticket, 'ticket');
            this.$router.push(newTicket._id);
        },
    },

    async created() {
        eventBus.$on('update-list', async (item) => {
            this.ticket = item;
        });
    },
};
</script>

<style scoped>
@import './../../assets/css/single.css';
</style>
