<template>
    <div class="col-sm-12">
        <h2>Statistics</h2>
        <br />
        <table class="table">
            <tbody>
                <tr>
                    <td><strong>From ...</strong></td>
                    <td>
                        <input
                            type="date"
                            class="form-control form-control-sm bg-dark text-light"
                            :value="startDate && startDate.split('T')[0]"
                            @input="startDate = $event.target.value"
                        />
                    </td>
                </tr>
                <tr>
                    <td><strong>... To</strong></td>
                    <td>
                        <input
                            type="date"
                            class="form-control form-control-sm bg-dark text-light"
                            :value="endDate && endDate.split('T')[0]"
                            @input="endDate = $event.target.value"
                        />
                    </td>
                </tr>
            </tbody>
        </table>

        <button
            type="button"
            class="btn btn-outline-success"
            @click="getStats()"
        >
            Get stats
        </button>
        <br />
        <br />
        <br />
        <br />
        <div v-if="stats[0]">
            <h4>Bookings vs capacities</h4>
            <table class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Summit</th>
                        <th>Crater</th>
                        <th>Total</th>
                        <th v-if="stats[2].tours">School groups</th>
                        <th v-if="stats[3].tours">Special</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <strong>Tours open</strong>
                        </td>
                        <td>
                            {{ stats[0].tours }}
                        </td>
                        <td>
                            {{ stats[1].tours }}
                        </td>
                        <td>
                            {{ stats[0].tours + stats[1].tours }}
                        </td>
                        <td v-if="stats[2].tours">
                            {{ stats[2].tours }}
                        </td>
                        <td v-if="stats[3].tours">
                            {{ stats[3].tours }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Total capacity</strong>
                        </td>
                        <td>
                            {{ stats[0].capacity }}
                        </td>
                        <td>
                            {{ stats[1].capacity }}
                        </td>
                        <td>
                            {{ stats[0].capacity + stats[1].capacity }}
                        </td>
                        <td v-if="stats[2].tours">
                            {{ stats[2].capacity }}
                        </td>
                        <td v-if="stats[3].tours">
                            {{ stats[3].capacity }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Bookings</strong>
                        </td>
                        <td>
                            {{ stats[0].booked }}
                        </td>
                        <td>
                            {{ stats[1].booked }}
                        </td>
                        <td>
                            {{ stats[0].booked + stats[1].booked }}
                        </td>
                        <td v-if="stats[2].tours">
                            {{ stats[2].booked }}
                        </td>
                        <td v-if="stats[3].tours">
                            {{ stats[3].booked }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Occupancy rate</strong>
                        </td>
                        <td>
                            {{
                                Math.round(
                                    (stats[0].booked / stats[0].capacity) * 100
                                )
                            }}
                            %
                        </td>
                        <td>
                            {{
                                Math.round(
                                    (stats[1].booked / stats[1].capacity) * 100
                                )
                            }}
                            %
                        </td>
                        <td>
                            {{
                                Math.round(
                                    ((stats[0].booked + stats[1].booked) /
                                        (stats[0].capacity +
                                            stats[1].capacity)) *
                                        100
                                )
                            }}
                            %
                        </td>
                        <td v-if="stats[2].tours">
                            {{ stats[2].booked }}
                        </td>
                        <td v-if="stats[3].tours">
                            {{ stats[3].booked }}
                        </td>
                    </tr>
                </tbody>
            </table>

            <br />
            <br />

            <h4>Tour outcomes</h4>
            <table class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Summit</th>
                        <th>Crater</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <strong>Clear</strong>
                        </td>
                        <td>
                            {{ stats[0].outcomes.clear }}
                            ({{
                                Math.round(
                                    (stats[0].outcomes.clear /
                                        stats[0].booked) *
                                        100
                                )
                            }}
                            %)
                        </td>
                        <td>
                            {{ stats[1].outcomes.clear }}
                            ({{
                                Math.round(
                                    (stats[1].outcomes.clear /
                                        stats[1].booked) *
                                        100
                                )
                            }}
                            %)
                        </td>
                        <td>
                            {{
                                stats[0].outcomes.clear +
                                stats[1].outcomes.clear
                            }}
                            ({{
                                Math.round(
                                    ((stats[0].outcomes.clear +
                                        stats[1].outcomes.clear) /
                                        (stats[0].booked + stats[1].booked)) *
                                        100
                                )
                            }}
                            %)
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Affected by weather</strong>
                        </td>
                        <td>
                            {{ stats[0].outcomes.weathered }}
                            ({{
                                Math.round(
                                    (stats[0].outcomes.weathered /
                                        stats[0].booked) *
                                        100
                                )
                            }}
                            %)
                        </td>
                        <td>
                            {{ stats[1].outcomes.weathered }}
                            ({{
                                Math.round(
                                    (stats[1].outcomes.weathered /
                                        stats[1].booked) *
                                        100
                                )
                            }}
                            %)
                        </td>
                        <td>
                            {{
                                stats[0].outcomes.weathered +
                                stats[1].outcomes.weathered
                            }}
                            ({{
                                Math.round(
                                    ((stats[0].outcomes.weathered +
                                        stats[1].outcomes.weathered) /
                                        (stats[0].booked + stats[1].booked)) *
                                        100
                                )
                            }}
                            %)
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Cancelled / Indoors</strong>
                        </td>
                        <td>
                            {{ stats[0].outcomes.nostargazing }}
                            ({{
                                Math.round(
                                    (stats[0].outcomes.nostargazing /
                                        stats[0].booked) *
                                        100
                                )
                            }}
                            %)
                        </td>
                        <td>
                            {{ stats[1].outcomes.nostargazing }}
                            ({{
                                Math.round(
                                    (stats[1].outcomes.nostargazing /
                                        stats[1].booked) *
                                        100
                                )
                            }}
                            %)
                        </td>
                        <td>
                            {{
                                stats[0].outcomes.nostargazing +
                                stats[1].outcomes.nostargazing
                            }}
                            ({{
                                Math.round(
                                    ((stats[0].outcomes.nostargazing +
                                        stats[1].outcomes.nostargazing) /
                                        (stats[0].booked + stats[1].booked)) *
                                        100
                                )
                            }}
                            %)
                        </td>
                    </tr>
                </tbody>
            </table>

            <br />
            <br />

            <h4>Refunds</h4>
            <table class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Mount John</th>
                        <th>Cowan's</th>
                        <th v-if="stats[2].tours">School groups</th>
                        <th v-if="stats[3].tours">Special</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <strong>Post tour 20% refund</strong>
                        </td>
                        <td>
                            {{ stats[0].postTour20 }}
                        </td>
                        <td>
                            {{ stats[1].postTour20 }}
                        </td>
                        <td v-if="stats[2].tours">
                            {{ stats[2].postTour20 }}
                        </td>
                        <td v-if="stats[3].tours">
                            {{ stats[3].postTour20 }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Post tour 50% refund</strong>
                        </td>
                        <td>
                            {{ stats[0].postTour50 }}
                        </td>
                        <td>
                            {{ stats[1].postTour50 }}
                        </td>
                        <td v-if="stats[2].tours">
                            {{ stats[2].postTour50 }}
                        </td>
                        <td v-if="stats[3].tours">
                            {{ stats[3].postTour50 }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Post tour 80% refund</strong>
                        </td>
                        <td>
                            {{ stats[0].postTour80 }}
                        </td>
                        <td>
                            {{ stats[1].postTour80 }}
                        </td>
                        <td v-if="stats[2].tours">
                            {{ stats[2].postTour80 }}
                        </td>
                        <td v-if="stats[3].tours">
                            {{ stats[3].postTour80 }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Full refund</strong>
                            <br />Including refund option
                        </td>
                        <td>
                            {{
                                stats[0].postTourFull +
                                stats[0].outdoors.refunds +
                                stats[0].outdoorsOptions.refunds +
                                stats[0].indoors.refunds
                            }}
                        </td>
                        <td>
                            {{
                                stats[1].postTourFull +
                                stats[1].outdoors.refunds +
                                stats[1].outdoorsOptions.refunds +
                                stats[1].indoors.refunds
                            }}
                        </td>
                        <td v-if="stats[2].tours">
                            {{
                                stats[2].postTourFull +
                                stats[2].outdoors.refunds +
                                stats[2].outdoorsOptions.refunds +
                                stats[2].indoors.refunds
                            }}
                        </td>
                        <td v-if="stats[3].tours">
                            {{
                                stats[3].postTourFull +
                                stats[3].outdoors.refunds +
                                stats[3].outdoorsOptions.refunds +
                                stats[3].indoors.refunds
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Total refund</strong>
                            <br />In pax value
                        </td>
                        <td>
                            {{ stats[0].totalRefund }}
                        </td>
                        <td>
                            {{ stats[1].totalRefund }}
                        </td>
                        <td v-if="stats[2].tours">
                            {{ stats[2].totalRefund }}
                        </td>
                        <td v-if="stats[3].tours">
                            {{ stats[3].totalRefund }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Overall refunds</strong>
                            <br />
                            Total refunds vs bookings
                        </td>
                        <td>
                            {{
                                Math.round(
                                    (stats[0].totalRefund / stats[0].booked) *
                                        100
                                )
                            }}
                            %
                        </td>
                        <td>
                            {{
                                Math.round(
                                    (stats[1].totalRefund / stats[1].booked) *
                                        100
                                )
                            }}
                            %
                        </td>
                        <td v-if="stats[2].tours">
                            {{
                                Math.round(
                                    (stats[2].totalRefund / stats[2].booked) *
                                        100
                                )
                            }}
                            %
                        </td>
                        <td v-if="stats[3].tours">
                            {{
                                Math.round(
                                    (stats[3].totalRefund / stats[3].booked) *
                                        100
                                )
                            }}
                            %
                        </td>
                    </tr>
                </tbody>
            </table>

            <br />
            <br />

            <!-- <h4>Tours</h4>
            <table class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Mount John</th>
                        <th>Cowan's</th>
                        <th v-if="stats[2].tours">School groups</th>
                        <th v-if="stats[3].tours">Special</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <strong>Open tours</strong>
                        </td>
                        <td>
                            {{ stats[0].tours }}
                        </td>
                        <td>
                            {{ stats[1].tours }}
                        </td>
                        <td v-if="stats[2].tours">
                            {{ stats[2].tours }}
                        </td>
                        <td v-if="stats[3].tours">
                            {{ stats[3].tours }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Total capacity</strong>
                        </td>
                        <td>
                            {{ stats[0].capacity }}
                        </td>
                        <td>
                            {{ stats[1].capacity }}
                        </td>
                        <td v-if="stats[2].tours">
                            {{ stats[2].capacity }}
                        </td>
                        <td v-if="stats[3].tours">
                            {{ stats[3].capacity }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Bookings</strong>
                        </td>
                        <td>
                            {{ stats[0].booked }}
                        </td>
                        <td>
                            {{ stats[1].booked }}
                        </td>
                        <td v-if="stats[2].tours">
                            {{ stats[2].booked }}
                        </td>
                        <td v-if="stats[3].tours">
                            {{ stats[3].booked }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Outdoors - no options</strong>
                        </td>
                        <td>
                            {{ stats[0].outdoors.pax }}
                        </td>
                        <td>
                            {{ stats[1].outdoors.pax }}
                        </td>
                        <td v-if="stats[2].tours">
                            {{ stats[2].outdoors.pax }}
                        </td>
                        <td v-if="stats[3].tours">
                            {{ stats[3].outdoors.pax }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Outdoors - options offered</strong>
                        </td>
                        <td>
                            {{ stats[0].outdoorsOptions.pax }}
                        </td>
                        <td>
                            {{ stats[1].outdoorsOptions.pax }}
                        </td>
                        <td v-if="stats[2].tours">
                            {{ stats[2].outdoorsOptions.pax }}
                        </td>
                        <td v-if="stats[3].tours">
                            {{ stats[3].outdoorsOptions.pax }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Indoors</strong>
                        </td>
                        <td>
                            {{ stats[0].indoors.pax }}
                        </td>
                        <td>
                            {{ stats[1].indoors.pax }}
                        </td>
                        <td v-if="stats[2].tours">
                            {{ stats[2].indoors.pax }}
                        </td>
                        <td v-if="stats[3].tours">
                            {{ stats[3].indoors.pax }}
                        </td>
                    </tr>
                </tbody>
            </table>

            <br />
            <br />

            <h4>Outcomes</h4>
            <table class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Outdoors</th>
                        <th>Options offered</th>
                        <th>Indoors</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <strong>Total pax</strong>
                        </td>
                        <td>
                            {{
                                stats[0].outdoors.pax +
                                stats[1].outdoors.pax +
                                stats[2].outdoors.pax +
                                stats[3].outdoors.pax
                            }}
                        </td>
                        <td>
                            {{
                                stats[0].outdoorsOptions.pax +
                                stats[1].outdoorsOptions.pax +
                                stats[2].outdoorsOptions.pax +
                                stats[3].outdoorsOptions.pax
                            }}
                        </td>
                        <td>
                            {{
                                stats[0].indoors.pax +
                                stats[1].indoors.pax +
                                stats[2].indoors.pax +
                                stats[3].indoors.pax
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Joined</strong>
                        </td>
                        <td>
                            {{
                                stats[0].outdoors.joined +
                                stats[1].outdoors.joined +
                                stats[2].outdoors.joined +
                                stats[3].outdoors.joined
                            }}
                            &nbsp;({{
                                Math.round(
                                    ((stats[0].outdoors.joined +
                                        stats[1].outdoors.joined +
                                        stats[2].outdoors.joined +
                                        stats[3].outdoors.joined) /
                                        (stats[0].outdoors.pax +
                                            stats[1].outdoors.pax +
                                            stats[2].outdoors.pax +
                                            stats[3].outdoors.pax)) *
                                        100
                                )
                            }}%)
                        </td>
                        <td>
                            {{
                                stats[0].outdoorsOptions.joined +
                                stats[1].outdoorsOptions.joined +
                                stats[2].outdoorsOptions.joined +
                                stats[3].outdoorsOptions.joined
                            }}
                            &nbsp;({{
                                Math.round(
                                    ((stats[0].outdoorsOptions.joined +
                                        stats[1].outdoorsOptions.joined +
                                        stats[2].outdoorsOptions.joined +
                                        stats[3].outdoorsOptions.joined) /
                                        (stats[0].outdoorsOptions.pax +
                                            stats[1].outdoorsOptions.pax +
                                            stats[2].outdoorsOptions.pax +
                                            stats[3].outdoorsOptions.pax)) *
                                        100
                                )
                            }}%)
                        </td>
                        <td>
                            {{
                                stats[0].indoors.joined +
                                stats[1].indoors.joined +
                                stats[2].indoors.joined +
                                stats[3].indoors.joined
                            }}
                            &nbsp;({{
                                Math.round(
                                    ((stats[0].indoors.joined +
                                        stats[1].indoors.joined +
                                        stats[2].indoors.joined +
                                        stats[3].indoors.joined) /
                                        (stats[0].indoors.pax +
                                            stats[1].indoors.pax +
                                            stats[2].indoors.pax +
                                            stats[3].indoors.pax)) *
                                        100
                                )
                            }}%)
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Transfers</strong>
                        </td>
                        <td>
                            {{
                                stats[0].outdoors.transfers +
                                stats[1].outdoors.transfers +
                                stats[2].outdoors.transfers +
                                stats[3].outdoors.transfers
                            }}
                        </td>
                        <td>
                            {{
                                stats[0].outdoorsOptions.transfers +
                                stats[1].outdoorsOptions.transfers +
                                stats[2].outdoorsOptions.transfers +
                                stats[3].outdoorsOptions.transfers
                            }}
                        </td>
                        <td>
                            {{
                                stats[0].indoors.transfers +
                                stats[1].indoors.transfers +
                                stats[2].indoors.transfers +
                                stats[3].indoors.transfers
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Open dated</strong>
                        </td>
                        <td>
                            {{
                                stats[0].outdoors.openDated +
                                stats[1].outdoors.openDated +
                                stats[2].outdoors.openDated +
                                stats[3].outdoors.openDated
                            }}
                        </td>
                        <td>
                            {{
                                stats[0].outdoorsOptions.openDated +
                                stats[1].outdoorsOptions.openDated +
                                stats[2].outdoorsOptions.openDated +
                                stats[3].outdoorsOptions.openDated
                            }}
                        </td>
                        <td>
                            {{
                                stats[0].indoors.openDated +
                                stats[1].indoors.openDated +
                                stats[2].indoors.openDated +
                                stats[3].indoors.openDated
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Refunds</strong>
                        </td>
                        <td>
                            {{
                                stats[0].outdoors.refunds +
                                stats[1].outdoors.refunds +
                                stats[2].outdoors.refunds +
                                stats[3].outdoors.refunds
                            }}
                        </td>
                        <td>
                            {{
                                stats[0].outdoorsOptions.refunds +
                                stats[1].outdoorsOptions.refunds +
                                stats[2].outdoorsOptions.refunds +
                                stats[3].outdoorsOptions.refunds
                            }}
                        </td>
                        <td>
                            {{
                                stats[0].indoors.refunds +
                                stats[1].indoors.refunds +
                                stats[2].indoors.refunds +
                                stats[3].indoors.refunds
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>No show</strong>
                        </td>
                        <td>
                            {{
                                stats[0].outdoors.noShow +
                                stats[1].outdoors.noShow +
                                stats[2].outdoors.noShow +
                                stats[3].outdoors.noShow
                            }}
                        </td>
                        <td>
                            {{
                                stats[0].outdoorsOptions.noShow +
                                stats[1].outdoorsOptions.noShow +
                                stats[2].outdoorsOptions.noShow +
                                stats[3].outdoorsOptions.noShow
                            }}
                        </td>
                        <td>
                            {{
                                stats[0].indoors.noShow +
                                stats[1].indoors.noShow +
                                stats[2].indoors.noShow +
                                stats[3].indoors.noShow
                            }}
                        </td>
                    </tr>
                </tbody>
            </table>

            <br />
            <br />

            <h4>Refunds</h4>
            <table class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Mount John</th>
                        <th>Cowan's</th>
                        <th v-if="stats[2].tours">School groups</th>
                        <th v-if="stats[3].tours">Special</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <strong>20% refund</strong>
                        </td>
                        <td>
                            {{ stats[0].postTour20 }}
                        </td>
                        <td>
                            {{ stats[1].postTour20 }}
                        </td>
                        <td v-if="stats[2].tours">
                            {{ stats[2].postTour20 }}
                        </td>
                        <td v-if="stats[3].tours">
                            {{ stats[3].postTour20 }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>50% refund</strong>
                        </td>
                        <td>
                            {{ stats[0].postTour50 }}
                        </td>
                        <td>
                            {{ stats[1].postTour50 }}
                        </td>
                        <td v-if="stats[2].tours">
                            {{ stats[2].postTour50 }}
                        </td>
                        <td v-if="stats[3].tours">
                            {{ stats[3].postTour50 }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>80% refund</strong>
                        </td>
                        <td>
                            {{ stats[0].postTour80 }}
                        </td>
                        <td>
                            {{ stats[1].postTour80 }}
                        </td>
                        <td v-if="stats[2].tours">
                            {{ stats[2].postTour80 }}
                        </td>
                        <td v-if="stats[3].tours">
                            {{ stats[3].postTour80 }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Full refund</strong>
                            <br />Including refund option
                        </td>
                        <td>
                            {{
                                stats[0].postTourFull +
                                stats[0].outdoors.refunds +
                                stats[0].outdoorsOptions.refunds +
                                stats[0].indoors.refunds
                            }}
                        </td>
                        <td>
                            {{
                                stats[1].postTourFull +
                                stats[1].outdoors.refunds +
                                stats[1].outdoorsOptions.refunds +
                                stats[1].indoors.refunds
                            }}
                        </td>
                        <td v-if="stats[2].tours">
                            {{
                                stats[2].postTourFull +
                                stats[2].outdoors.refunds +
                                stats[2].outdoorsOptions.refunds +
                                stats[2].indoors.refunds
                            }}
                        </td>
                        <td v-if="stats[3].tours">
                            {{
                                stats[3].postTourFull +
                                stats[3].outdoors.refunds +
                                stats[3].outdoorsOptions.refunds +
                                stats[3].indoors.refunds
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Total refund</strong>
                            <br />In pax value
                        </td>
                        <td>
                            {{ stats[0].totalRefund }}
                        </td>
                        <td>
                            {{ stats[1].totalRefund }}
                        </td>
                        <td v-if="stats[2].tours">
                            {{ stats[2].totalRefund }}
                        </td>
                        <td v-if="stats[3].tours">
                            {{ stats[3].totalRefund }}
                        </td>
                    </tr>
                </tbody>
            </table> -->
        </div>
    </div>
</template>

<script>
import { apiClient } from '@/services/apiClient.js';

export default {
    data: function () {
        return {
            startDate: '',
            endDate: '',
            stats: {},
        };
    },

    methods: {
        async fetchData() {
            this.stats = await apiClient.getStatsByDateRange(
                this.startDate,
                this.endDate
            );
        },

        async getStats() {
            if (!this.startDate || !this.endDate) {
                this.$store.commit('alert/error', 'Wrong date range');
            } else {
                await this.fetchData();
            }
        },
    },
};
</script>

<style scoped></style>
