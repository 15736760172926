<template>
    <div class="col-sm-12">
        <h2>SQM reading</h2>

        <br />

        <table class="table">
            <tbody>
                <tr>
                    <td><strong>Measurement 1</strong></td>
                    <td>
                        <input
                            type="number"
                            class="form-control form-control-sm bg-dark text-light"
                            v-model="data[0]"
                        />
                    </td>
                </tr>

                <tr>
                    <td><strong>Measurement 2</strong></td>
                    <td>
                        <input
                            type="number"
                            class="form-control form-control-sm bg-dark text-light"
                            v-model="data[1]"
                        />
                    </td>
                </tr>

                <tr>
                    <td><strong>Measurement 3</strong></td>
                    <td>
                        <input
                            type="number"
                            class="form-control form-control-sm bg-dark text-light"
                            v-model="data[2]"
                        />
                    </td>
                </tr>

                <tr>
                    <td><strong>Measurement 4</strong></td>
                    <td>
                        <input
                            type="number"
                            class="form-control form-control-sm bg-dark text-light"
                            v-model="data[3]"
                        />
                    </td>
                </tr>

                <tr>
                    <td><strong>Measurement 5</strong></td>
                    <td>
                        <input
                            type="number"
                            class="form-control form-control-sm bg-dark text-light"
                            v-model="data[4]"
                        />
                    </td>
                </tr>

                <tr>
                    <td><strong>Temperature</strong></td>
                    <td>
                        <input
                            type="number"
                            class="form-control form-control-sm bg-dark text-light"
                            v-model.number="sqm.temperature"
                        />
                    </td>
                </tr>

                <tr>
                    <td><strong>Note</strong></td>
                    <td class="text-light">
                        <textarea
                            rows="5"
                            v-model="sqm.note"
                            class="form-control form-control-sm bg-dark text-light"
                        ></textarea>
                    </td>
                </tr>
            </tbody>
        </table>

        <br />
        <div v-if="user.role > 3">
            <button
                type="button"
                class="btn btn-outline-success"
                @click="saveSQM()"
            >
                Save SQM
            </button>
        </div>
    </div>
</template>

<script>
import { apiClient } from '../../services/apiClient.js';
import { eventBus } from '../../services/eventBus.js';

export default {
    data: function () {
        return {
            data: [Number],
            sqm: {
                measurements: [],
            },
        };
    },

    computed: {
        user() {
            return this.$store.state.auth.user;
        },

        settings() {
            return this.$store.state.settings.settings;
        },
    },

    methods: {
        async saveSQM() {
            if (this.$route.params.id.length > 10) {
                await apiClient.itemUpdate(this.sqm, 'sqm');
            } else {
                this.sqm.by = this.user;
                this.sqm.measurements = this.data;
                await apiClient.itemCreate(this.sqm, 'sqm');
            }
            this.$router.push('/sqm/list');
        },

        async fetchData() {
            this.sqm = await apiClient.getItem(this.$route.params.id, 'sqm');
            this.data = this.sqm.measurements;
        },
    },

    async created() {
        if (this.$route.params.id.length > 10) {
            this.fetchData();
        }
        eventBus.$on('update-list', async (item) => {
            this.sqm = item;
        });
    },
};
</script>

<style scoped>
@import './../../assets/css/single.css';
</style>
