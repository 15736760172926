<template>
    <div class="col-sm-12">
        <!-- Page title -->
        <h2>Task list</h2>
        <br />

        <!-- Add button -->
        <router-link
            tag="button"
            to="create"
            type="button"
            class="btn btn-outline-success"
        >
            New task
        </router-link>

        <br />
        <br />

        <app-table
            :category="'task'"
            :items="tasks"
            :cols="[
                { title: 'Equipment', key: 'equipment', type: 'list' },
                { title: 'Name', key: 'name' },
                { title: 'Regularity', key: 'regularity' },
                { title: 'Due', key: 'dueDate', type: 'date' },
            ]"
        ></app-table>

        <!-- <table class="table table-hover" v-if="tasks && tasks.length > 0">
            <thead>
                <tr class="__tableHead">
                    <th>Equipment</th>
                    <th>Name</th>
                    <th>Regularity</th>
                    <th>Due</th>
                </tr>
            </thead>

            <tbody>
                <router-link
                    tag="tr"
                    v-for="task in tasks"
                    :key="task._id"
                    :to="task._id"
                    :class="{
                        __unverified: task.status
                    }"
                >
                    <td>
                        <ul class="__tableList">
                            <li v-for="i in task.equipment" :key="i._id">
                                {{ i.name }}
                            </li>
                        </ul>
                    </td>

                    <td>{{ task.name }}</td>
                    <td>{{ task.regularity }}</td>
                    <td>
                        <app-date :value="task.dueDate"></app-date>
                    </td>
                </router-link>
            </tbody>
        </table>
        <p v-else class="__unverified">Nothing to see here.</p> -->

        <!-- Show closed tasks -->
        <p
            class="__link"
            @click="
                showClosed = !showClosed;
                fetchData();
            "
        >
            <span v-if="showClosed">Hide closed tasks</span>
            <span v-else>Show closed tasks</span>
        </p>
    </div>
</template>

<script>
import { apiClient } from './../../services/apiClient.js';
import Table from './../Utils/Table.vue';

export default {
    data: function () {
        return {
            tasks: [],
            showClosed: false,
        };
    },

    components: { 'app-table': Table },

    computed: {
        user() {
            return this.$store.state.auth.user;
        },
    },

    methods: {
        async fetchData() {
            this.tasks = await apiClient.getList('task');
            this.tasks = this.tasks.filter((item) => {
                if (this.showClosed) {
                    return true;
                } else {
                    return !item.status;
                }
            });
        },
    },

    async created() {
        await this.fetchData();
    },
};
</script>

<style scoped></style>
