<template>
    <div class="col-sm-12">
        <h2>Ticket details</h2>
        <p class="__secondary">ID: {{ ticket._id }}</p>

        <br />

        <table class="table">
            <tbody>
                <tr>
                    <td><strong>Equipment</strong></td>
                    <td class="text-light">
                        <app-autocomplete-list
                            :user="user"
                            type="equipment"
                            :item="ticket"
                            propPath="equipment"
                        ></app-autocomplete-list>
                    </td>
                </tr>

                <tr>
                    <td><strong>History</strong></td>
                    <td class="text-light">
                        <table class="table text-light">
                            <thead>
                                <tr class="__tableHead">
                                    <th>Note</th>
                                    <th>By</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(el, index) in ticket.history"
                                    :key="el._id"
                                    :class="{
                                        __tableSuccess: el.status,
                                        __tableDanger:
                                            index === ticket.history.length - 1,
                                    }"
                                >
                                    <td class="__text-bloc">
                                        <p v-for="note in el.note" :key="note">
                                            {{ note }}
                                        </p>
                                    </td>
                                    <td>{{ el.by.name }}</td>
                                    <td>
                                        <app-date :value="el.date"></app-date>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td><strong>Add note</strong></td>
                    <td class="text-light">
                        <textarea
                            rows="5"
                            v-model="ticket.note"
                            class="form-control form-control-sm bg-dark text-light"
                        ></textarea>
                    </td>
                </tr>

                <tr>
                    <td><strong>Priority</strong></td>
                    <td class="text-light">
                        <select
                            id="priority"
                            class="form-control-sm bg-dark text-light"
                            v-model="ticket.priority"
                        >
                            <option
                                v-for="(
                                    ticketPriority, key
                                ) in settings.ticketPriority"
                                :key="key"
                                :value="key"
                            >
                                {{ ticketPriority }}
                            </option>
                        </select>
                    </td>
                </tr>

                <tr>
                    <td><strong>Done</strong></td>
                    <td class="text-light">
                        <div class="custom-control custom-switch">
                            <input
                                type="checkbox"
                                class="custom-control-input"
                                v-model="ticket.status"
                                id="ticketStatus"
                            />
                            <label
                                class="custom-control-label"
                                for="ticketStatus"
                            ></label>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td><strong>Added on</strong></td>
                    <td class="text-light">
                        <app-date :value="ticket.added"></app-date>
                    </td>
                </tr>

                <tr>
                    <td><strong>Updated on</strong></td>
                    <td class="text-light">
                        <app-date :value="ticket.updated"></app-date>
                    </td>
                </tr>
            </tbody>
        </table>

        <br />
        <br />

        <!-- Buttons -->
        <div v-if="user.role > 2">
            <button
                type="button"
                class="btn btn-outline-success"
                @click="updateTicket()"
            >
                Save ticket
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
                type="button"
                class="btn btn-outline-danger"
                @click="deleteTicket()"
            >
                Delete ticket
            </button>
        </div>
    </div>
</template>

<script>
import { eventBus } from './../../services/eventBus.js';
import { apiClient } from './../../services/apiClient.js';
import AutocompleteList from './../Utils/AutocompleteList.vue';
import DateDisplay from './../Utils/Date.vue';

export default {
    data: function () {
        return {
            ticket: {},
        };
    },

    components: {
        'app-autocomplete-list': AutocompleteList,
        'app-date': DateDisplay,
    },

    computed: {
        user() {
            return this.$store.state.auth.user;
        },

        settings() {
            return this.$store.state.settings.settings;
        },
    },

    methods: {
        async updateTicket() {
            await apiClient.itemUpdate(this.ticket, 'ticket');
            await this.fetchData();
        },

        async deleteTicket() {
            if (await apiClient.itemDelete(this.ticket, 'ticket')) {
                this.ticket = {};
                this.$router.push('list');
            }
        },

        async fetchData() {
            this.ticket = await apiClient.getItem(
                this.$route.params.id,
                'ticket'
            );
        },
    },

    async created() {
        await this.fetchData();

        eventBus.$on('update-list', async (item) => {
            this.ticket = item;
        });
    },
};
</script>

<style scoped>
@import './../../assets/css/single.css';
</style>
