<template>
    <nav class="col-md-2 d-md-block sidebar">
        <div class="sidebar-sticky">
            <ul class="nav flex-column">
                <p class="__menu-separator">Reports</p>
                <router-link tag="a" :to="'/report'" class="nav-item">
                    <i class="fas fa-tasks"></i>
                    &nbsp;&nbsp;Night report
                </router-link>
                <router-link tag="a" :to="'/report/view'" class="nav-item">
                    <i class="fas fa-eye"></i>
                    &nbsp;&nbsp;View reports
                </router-link>
            </ul>
            <ul class="nav flex-column">
                <p class="__menu-separator">Maintenance</p>
                <router-link
                    tag="a"
                    :to="'/equipment/gear/list'"
                    class="nav-item"
                >
                    <i class="fas fa-rocket"></i>
                    &nbsp;&nbsp;Equipment
                </router-link>
                <router-link
                    tag="a"
                    :to="'/equipment/vehicle/list'"
                    class="nav-item"
                >
                    <i class="fas fa-car"></i>
                    &nbsp;&nbsp;Vehicles
                </router-link>
                <router-link
                    tag="a"
                    :to="'/equipment/facility/list'"
                    class="nav-item"
                >
                    <i class="fas fa-building"></i>
                    &nbsp;&nbsp;Facilities
                </router-link>
                <router-link
                    tag="a"
                    :to="'/maintenance/ticket/list'"
                    class="nav-item"
                >
                    <i class="fas fa-exclamation-circle"></i>
                    &nbsp;&nbsp;Tickets
                </router-link>
                <router-link
                    tag="a"
                    :to="'/maintenance/task/list'"
                    class="nav-item"
                >
                    <i class="fas fa-tools"></i>
                    &nbsp;&nbsp;Tasks
                </router-link>
                <a
                    href="https://forms.gle/jY7XCb4jbcoxQu4t6"
                    class="nav-item"
                    target="_blank"
                >
                    <i class="fas fa-desktop"></i>
                    &nbsp;&nbsp;Z1 incident
                </a>
            </ul>
            <ul v-if="user.role > 3" class="nav flex-column">
                <p class="__menu-separator">Tools</p>
                <router-link tag="a" :to="'/stats'" class="nav-item">
                    <i class="fas fa-percent"></i>
                    &nbsp;&nbsp;Statistics
                </router-link>
                <router-link tag="a" :to="'/sqm/list'" class="nav-item">
                    <i class="fas fa-lightbulb"></i>
                    &nbsp;&nbsp;SQM
                </router-link>
                <router-link tag="a" :to="'/ssdata'" class="nav-item">
                    <i class="fas fa-moon"></i>
                    &nbsp;&nbsp;Ephemeris
                </router-link>
                <router-link tag="a" :to="'/user/list'" class="nav-item">
                    <i class="fas fa-user"></i>
                    &nbsp;&nbsp;User list
                </router-link>
            </ul>
        </div>
    </nav>
</template>

<script>
export default {
    computed: {
        user() {
            return this.$store.state.auth.user;
        },
    },
};
</script>

<style>
@import './../assets/css/menu.css';
</style>
