<template>
    <div class="col-sm-12">
        <h2>Create user</h2>

        <br />

        <table class="table">
            <tbody>
                <tr>
                    <td><strong>User name</strong></td>
                    <td class="text-light">
                        <input
                            type="text"
                            class="form-control form-control-sm bg-dark text-light"
                            v-model="newUser.name"
                        />
                    </td>
                </tr>

                <tr>
                    <td><strong>Email address</strong></td>
                    <td class="text-light">
                        <input
                            type="text"
                            class="form-control form-control-sm bg-dark text-light"
                            v-model="newUser.email"
                        />
                    </td>
                </tr>

                <tr>
                    <td><strong>Role</strong></td>
                    <td class="text-light">
                        <select
                            id="location"
                            class="form-control-sm bg-dark text-light"
                            v-model="newUser.role"
                        >
                            <option
                                v-for="(role, key) in settings.userRole"
                                :key="key"
                                :value="key"
                            >
                                {{ role }}
                            </option>
                        </select>
                    </td>
                </tr>
            </tbody>
        </table>

        <br />
        <div v-if="user.role > 3">
            <button
                type="button"
                class="btn btn-outline-success"
                @click="createUser()"
            >
                Create user
            </button>
        </div>
    </div>
</template>

<script>
import { apiClient } from './../../services/apiClient.js';

export default {
    data: function () {
        return {
            newUser: {},
        };
    },

    computed: {
        user() {
            return this.$store.state.auth.user;
        },

        settings() {
            return this.$store.state.settings.settings;
        },
    },

    methods: {
        async createUser() {
            let res = await apiClient.itemCreate(this.newUser, 'user');
            this.$router.push(res._id);
        },
    },
};
</script>

<style scoped>
@import './../../assets/css/single.css';
</style>
