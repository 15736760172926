<template>
    <div v-if="item" class="__autocomplete">
        <ul
            v-if="model[propPath] && model[propPath].length > 0"
            class="__arrayList"
        >
            <li v-for="i in model[propPath]" :key="i._id">
                <i class="fas fa-times" @click="removeElement(i)"></i>
                <a
                    :href="'/' + type + '/' + i.cat + '/' + i._id"
                    target="_blank"
                >
                    {{ i.name }}
                </a>
            </li>
        </ul>
        <input
            type="text"
            class="form-control form-control-sm bg-dark text-light"
            v-model="inputValue"
            @input="autocomplete()"
            @click="autocompleteVisibility = false"
        />
        <ul
            class="__autocompleteResults bg-dark"
            v-show="this.autocompleteVisibility"
        >
            <li
                v-for="result in autocompleteResults"
                :key="result._id"
                class="__autocompleteResult"
                @click="
                    addElement(result);
                    autocomplete();
                    autocompleteVisibility = false;
                "
            >
                <p class="">
                    {{ result.name }}
                </p>
                <p class="__secondary">
                    {{ result.description }}
                </p>
                <p class="__secondary">
                    {{ result.email }}
                </p>
            </li>
        </ul>
    </div>
</template>

<script>
import { apiClient } from './../../services/apiClient.js';
import { eventBus } from './../../services/eventBus.js';

export default {
    data: function () {
        return {
            inputValue: '',
            fullList: [],
            autocompleteResults: [],
            autocompleteVisibility: false,
            localItem: {},
            // itemList: []
        };
    },

    props: {
        item: Object,
        type: String,
        propPath: String,
    },

    computed: {
        user() {
            return this.$store.state.auth.user;
        },

        model() {
            return this.$deepModel(this.localItem);
        },
    },

    methods: {
        autocomplete() {
            this.autocompleteVisibility = true;
            this.autocompleteResults = this.fullList.filter((i) => {
                return (
                    i.name.toLowerCase().search(this.inputValue.toLowerCase()) >
                        -1 ||
                    (i.description &&
                        i.description
                            .toLowerCase()
                            .search(this.inputValue.toLowerCase()) > -1)
                );
            });
        },

        removeElement(element) {
            this.model[this.propPath] = this.model[this.propPath].filter(
                (i) => {
                    return i._id != element._id;
                }
            );
            eventBus.updateList(this.localItem, this.propPath);
        },

        addElement(element) {
            this.model[this.propPath].push({
                name: element.name,
                _id: element._id,
                cat: element.cat,
            });
            eventBus.updateList(this.localItem, this.propPath);
        },
    },

    async created() {
        this.fullList = await apiClient.getList(this.type);
        this.localItem = await Object.assign({}, this.item);
        if (!this.model[this.propPath]) this.model[this.propPath] = [];
    },
};
</script>

<style scoped>
@import './../../assets/css/autocomplete.css';
</style>
