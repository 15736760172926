<template>
    <div class="col-sm-12">
        <!-- Page title -->
        <h2>Ticket list</h2>
        <br />

        <!-- Add button -->
        <router-link
            tag="button"
            to="create"
            type="button"
            class="btn btn-outline-success"
        >
            New ticket
        </router-link>

        <br />
        <br />

        <!-- Ticket list -->
        <app-table
            :category="'ticket'"
            :items="tickets"
            :cols="[
                { title: 'Equipment', key: 'equipment', type: 'list' },
                { title: 'Description', key: 'description' },
                { title: 'Priority', key: 'priority', type: 'priority' },
                { title: 'Added', key: 'added', type: 'date' },
            ]"
        ></app-table>

        <!-- <table class="table table-hover" v-if="tickets && tickets.length > 0">
            <thead>
                <tr class="__tableHead">
                    <th>Equipment</th>
                    <th>Description</th>
                    <th>Updated</th>
                    <th>Added</th>
                </tr>
            </thead>

            <tbody>
                <router-link
                    tag="tr"
                    v-for="ticket in tickets"
                    :key="ticket._id"
                    :to="ticket._id"
                    :class="{
                        __unverified: ticket.status
                    }"
                >
                    <td>
                        <ul class="__tableList">
                            <li v-for="i in ticket.equipment" :key="i._id">
                                {{ i.name }}
                            </li>
                        </ul>
                    </td>

                    <td>{{ ticket.description }}</td>
                    <td><app-date :value="ticket.updated"></app-date></td>
                    <td>
                        <app-date :value="ticket.updated"></app-date> ({{
                            ticket.by.name
                        }})
                    </td>
                </router-link>
            </tbody>
        </table>
        <p v-else class="__unverified">Nothing to see here.</p> -->

        <!-- Show closed tickets -->
        <p
            class="__link"
            @click="
                showClosed = !showClosed;
                fetchData();
            "
        >
            <span v-if="showClosed">Hide solved tickets</span>
            <span v-else>Show solved tickets</span>
        </p>
    </div>
</template>

<script>
import { apiClient } from './../../services/apiClient.js';
import Table from './../Utils/Table.vue';

export default {
    data: function () {
        return {
            tickets: [],
            showClosed: false,
        };
    },

    components: { 'app-table': Table },

    computed: {
        user() {
            return this.$store.state.auth.user;
        },
    },

    methods: {
        async fetchData() {
            this.tickets = await apiClient.getList('ticket');
            this.tickets = this.tickets.filter((item) => {
                if (this.showClosed) {
                    return true;
                } else {
                    return !item.status;
                }
            });
        },
    },

    async created() {
        await this.fetchData();
    },
};
</script>

<style scoped></style>
