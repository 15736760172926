<template>
    <div class="col-sm-12">
        <h2>Add new gear</h2>

        <br />

        <table class="table">
            <tbody v-if="cat === 'facility'">
                <tr>
                    <td><strong>Facility name</strong></td>
                    <td class="text-light">
                        <input
                            type="text"
                            class="form-control form-control-sm bg-dark text-light"
                            v-model="equipment.name"
                        />
                    </td>
                </tr>

                <tr>
                    <td><strong>Description</strong></td>
                    <td class="text-light">
                        <textarea
                            rows="5"
                            v-model="equipment.description"
                            class="form-control form-control-sm bg-dark text-light"
                        ></textarea>
                    </td>
                </tr>

                <tr>
                    <td><strong>Location</strong></td>
                    <td class="text-light">
                        <select
                            id="location"
                            class="form-control-sm bg-dark text-light"
                            v-model="equipment.location"
                        >
                            <option
                                v-for="(location, key) in settings.location"
                                :key="key"
                                :value="key"
                            >
                                {{ location }}
                            </option>
                        </select>
                    </td>
                </tr>

                <tr>
                    <td><strong>Status</strong></td>
                    <td class="text-light">
                        <select
                            id="location"
                            class="form-control-sm bg-dark text-light"
                            v-model="equipment.status"
                        >
                            <option
                                v-for="(
                                    status, key
                                ) in settings.equipmentStatus"
                                :key="key"
                                :value="key"
                            >
                                {{ status }}
                            </option>
                        </select>
                    </td>
                </tr>
            </tbody>

            <tbody v-if="cat === 'vehicle'">
                <tr>
                    <td><strong>Vehicle name</strong></td>
                    <td class="text-light">
                        <input
                            type="text"
                            class="form-control form-control-sm bg-dark text-light"
                            v-model="equipment.name"
                        />
                    </td>
                </tr>

                <tr>
                    <td><strong>Type</strong></td>
                    <td class="text-light">
                        <select
                            id="type"
                            class="form-control-sm bg-dark text-light"
                            v-model="equipment.type"
                        >
                            <option
                                v-for="(type, key) in settings.vehicleType"
                                :key="key"
                                :value="key"
                            >
                                {{ type }}
                            </option>
                        </select>
                    </td>
                </tr>

                <tr>
                    <td><strong>License plate</strong></td>
                    <td class="text-light">
                        <input
                            type="text"
                            class="form-control form-control-sm bg-dark text-light"
                            v-model="equipment.description"
                        />
                    </td>
                </tr>

                <tr>
                    <td><strong>WoF expires</strong></td>
                    <td class="text-light">
                        <input
                            type="date"
                            class="form-control form-control-sm bg-dark text-light"
                            :value="
                                equipment.wof && equipment.wof.split('T')[0]
                            "
                            @input="equipment.wof = $event.target.value"
                        />
                    </td>
                </tr>

                <tr>
                    <td><strong>Registration expires</strong></td>
                    <td class="text-light">
                        <input
                            type="date"
                            class="form-control form-control-sm bg-dark text-light"
                            :value="
                                equipment.registration &&
                                equipment.registration.split('T')[0]
                            "
                            @input="
                                equipment.registration = $event.target.value
                            "
                        />
                    </td>
                </tr>

                <tr>
                    <td><strong>Status</strong></td>
                    <td class="text-light">
                        <select
                            id="location"
                            class="form-control-sm bg-dark text-light"
                            v-model="equipment.status"
                        >
                            <option
                                v-for="(
                                    status, key
                                ) in settings.equipmentStatus"
                                :key="key"
                                :value="key"
                            >
                                {{ status }}
                            </option>
                        </select>
                    </td>
                </tr>
            </tbody>

            <tbody v-if="cat === 'gear'">
                <tr>
                    <td><strong>Equipment name</strong></td>
                    <td class="text-light">
                        <input
                            type="text"
                            class="form-control form-control-sm bg-dark text-light"
                            v-model="equipment.name"
                        />
                    </td>
                </tr>

                <tr>
                    <td><strong>Type</strong></td>
                    <td class="text-light">
                        <select
                            id="type"
                            class="form-control-sm bg-dark text-light"
                            v-model="equipment.type"
                        >
                            <option
                                v-for="(type, key) in settings.equipmentType"
                                :key="key"
                                :value="key"
                            >
                                {{ type }}
                            </option>
                        </select>
                    </td>
                </tr>

                <tr>
                    <td><strong>Description</strong></td>
                    <td class="text-light">
                        <textarea
                            rows="5"
                            class="form-control form-control-sm bg-dark text-light"
                            v-model="equipment.description"
                        />
                    </td>
                </tr>

                <tr>
                    <td><strong>Location</strong></td>
                    <td class="text-light">
                        <select
                            id="location"
                            class="form-control-sm bg-dark text-light"
                            v-model="equipment.location"
                        >
                            <option
                                v-for="(location, key) in settings.location"
                                :key="key"
                                :value="key"
                            >
                                {{ location }}
                            </option>
                        </select>
                    </td>
                </tr>

                <tr>
                    <td><strong>Status</strong></td>
                    <td class="text-light">
                        <select
                            id="location"
                            class="form-control-sm bg-dark text-light"
                            v-model="equipment.status"
                        >
                            <option
                                v-for="(
                                    status, key
                                ) in settings.equipmentStatus"
                                :key="key"
                                :value="key"
                            >
                                {{ status }}
                            </option>
                        </select>
                    </td>
                </tr>
            </tbody>
        </table>

        <br />
        <div v-if="user.role > 3">
            <button
                type="button"
                class="btn btn-outline-success"
                @click="createEquipment()"
            >
                Create equipment
            </button>
        </div>
    </div>
</template>

<script>
import { apiClient } from './../../services/apiClient.js';
import { eventBus } from './../../services/eventBus.js';

export default {
    data: function () {
        return {
            equipment: {},
        };
    },

    computed: {
        user() {
            return this.$store.state.auth.user;
        },

        settings() {
            return this.$store.state.settings.settings;
        },

        cat: function () {
            return this.$route.params.category;
        },
    },

    methods: {
        async createEquipment() {
            let newEquipment = await apiClient.itemCreate(
                this.equipment,
                'equipment'
            );
            this.$router.push(newEquipment._id);
        },
    },

    async created() {
        eventBus.$on('update-list', async (item) => {
            this.equipment = item;
        });
    },
};
</script>

<style scoped>
@import './../../assets/css/single.css';
</style>
